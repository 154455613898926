<template>
  <v-container>
    <v-row class="mb-2">
      <!--
            <v-col v-if="Object.keys(medicationPlans).length > 0 && loading === false" class="text-right">
                <v-btn color="btn btn-primary"
                    v-if="helpers.checkForRole(keycloak.token, 'doctor') | helpers.checkForRole(keycloak.token, 'nurse')"
                    @click="dialog = true; this.fields = this.fieldsTemplate" prepend-icon="mdi-plus" class="mr-3">
                    Medikationsplan hinzufügen
                </v-btn>
            </v-col>
            -->
      <v-col
        v-if="
          Object.keys(medicationPlans).length > 0 &&
          checkIfOld(medicationPlans) &&
          loading === false
        "
        class="text-right"
      >
        <v-btn
          color="btn btn-primary"
          v-if="
            helpers.checkForRole(keycloak.token, 'doctor') |
              helpers.checkForRole(keycloak.token, 'nurse')
          "
          @click="
            discontinuedMedication = true;
            medication_to_refresh_data = medicationPlans[0];
            (medication_to_refresh_index = 0),
              (this.fields = medicationPlans[0].medications);
          "
          prepend-icon="mdi-plus"
          class="mr-3"
        >
          abgesetzte Medikamente
        </v-btn>
        <v-btn
          color="btn btn-primary"
          v-if="
            helpers.checkForRole(keycloak.token, 'doctor') |
              helpers.checkForRole(keycloak.token, 'nurse')
          "
          @click="
            dialog_refresh = true;
            medication_to_refresh_data = medicationPlans[0];
            (medication_to_refresh_index = 0),
              (this.fields = medicationPlans[0].medications);
          "
          prepend-icon="mdi-plus"
          class="mr-3"
        >
          Medikationsplan bearbeiten
        </v-btn>
      </v-col>
      <v-col
        v-if="
          (Object.keys(medicationPlans).length === 0 ||
            !checkIfOld(medicationPlans)) &&
          loading === false
        "
      >
        <v-btn
          color="btn btn-primary"
          v-if="
            helpers.checkForRole(keycloak.token, 'doctor') |
              helpers.checkForRole(keycloak.token, 'nurse')
          "
          @click="
            dialog = true;
            this.fields = this.fieldsTemplate;
          "
          prepend-icon="mdi-plus"
        >
          Neuen Medikationsplan anlegen
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col
        v-if="
          (Object.keys(medicationPlans).length === 0) &&
          loading === false
        "
      >
        <br /><br />
        <h3 align="center">Kein Medikationsplan vorhanden</h3>
      </v-col>
    </v-row>

    <v-expansion-panels variant="accordion" v-model="panel">
      <v-col
        v-if="Object.keys(medicationPlans).length > 0 && loading === false"
      >
        <v-row v-for="(data, index) in medicationPlans" :key="index">
          <v-expansion-panel>
            <v-expansion-panel-title v-if="data.creationDate">
              Medikationsplan vom
              {{ helpers.formatDateStringToGermanDateTime(data.plan_datetime) }}
              Uhr - erstellt am
              {{
                helpers
                  .formatDateStringToGermanDateTime(data.creationDate)
                  .slice(0, 10)
              }}
            </v-expansion-panel-title>
            <v-expansion-panel-title v-if="!data.creationDate">
              Medikationsplan vom
              {{ helpers.formatDateStringToGermanDateTime(data.plan_datetime) }}
              Uhr - keine Angabe
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <!--
                            <v-col class="text-right">
                                <v-btn color="btn btn-primary"
                                v-if="helpers.checkForRole(keycloak.token, 'doctor') | helpers.checkForRole(keycloak.token, 'nurse')"
                                @click="discontinuedMedication = true; medication_to_refresh_data = data; medication_to_refresh_index = index, this.fields = data.medications"
                                prepend-icon="mdi-plus" class="mr-3">
                                abgesetzte Medikamente
                                </v-btn>
                                <v-btn color="btn btn-primary"
                                v-if="helpers.checkForRole(keycloak.token, 'doctor') | helpers.checkForRole(keycloak.token, 'nurse')"
                                @click="dialog_refresh = true; medication_to_refresh_data = data; medication_to_refresh_index = index, this.fields = data.medications"
                                prepend-icon="mdi-plus" class="mr-3">
                                Medikationsplan bearbeiten
                                </v-btn>
                            </v-col>
                            -->
              <v-row v-if="data.reason">
                Grund für Aktualisierung: {{ data.reason }}
              </v-row>
              <v-row v-if="data.medications.parkinson_medications">
                <v-col>
                  <h5 class="mb-n2 mt-2">Parkinson Medikamente</h5>
                </v-col>
              </v-row>
              <v-row v-if="data.medications.parkinson_medications">
                <v-table>
                  <thead>
                    <tr>
                      <th class="text-left">Medikament</th>
                      <th class="text-left">mg/ml</th>
                      <th class="text-left">Wirkstoff</th>
                      <th class="text-left">Einnahmezeitpunkte und Menge</th>
                      <th class="text-left">Hinweis/Kommentar</th>
                      <th class="text-left">Einnahme seit</th>
                      <th class="text-left">Packungsgröße</th>
                      <th class="text-left">PZN</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(medication, index) in data.medications
                        .parkinson_medications"
                      :key="index"
                    >
                      <td class="text-left">
                        {{ medication.name }}
                      </td>
                      <td class="text-left">
                        {{ medication.dosage }}
                      </td>
                      <td class="text-left">
                        {{ medication.active_ingredient }}
                      </td>
                      <td
                        v-if="
                          medication.intakes.selected ===
                          'zu konkreten Uhrzeiten'
                        "
                        class="text-left"
                      >
                        <v-table>
                          <tr>
                            <td
                              v-for="(time, idx) in medication.intakes
                                .intake_dates"
                              :key="idx"
                              class="text-left"
                            >
                              {{ time }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              v-for="(amount, idx) in medication.intakes
                                .intake_amounts"
                              :key="idx"
                            >
                              {{ amount }}
                            </td>
                          </tr>
                        </v-table>
                      </td>
                      <td v-else class="text-left">
                        Bei Bedarf - siehe Kommentar
                      </td>
                      <td class="text-left">
                        {{ medication.hints }}
                      </td>
                      <td class="text-left">
                        <v-table>
                          <tr>
                            <td class="text-left">Wirkstoff:</td>
                            <td class="text-left">
                              {{ medication.ac_ing_since }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">Dosis:</td>
                            <td class="text-left">
                              {{ medication.dosis_since }}
                            </td>
                          </tr>
                        </v-table>
                      </td>
                      <td class="text-left">
                        {{ medication.package_size }}
                      </td>
                      <td class="text-left">
                        {{ medication.pzn }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-row>

              <v-row v-if="data.medications.further_medications">
                <v-col>
                  <h5 class="mb-n2 mt-2">Sonstige Medikamente</h5>
                </v-col>
              </v-row>
              <v-row v-if="data.medications.further_medications">
                <v-table>
                  <thead>
                    <tr>
                      <th class="text-left">Medikament</th>
                      <th class="text-left">mg/ml</th>
                      <th class="text-left">Wirkstoff</th>
                      <th class="text-left">Einnahmezeitpunkte und Menge</th>
                      <th class="text-left">Hinweis/Kommentar</th>
                      <th class="text-left">Einnahme seit</th>
                      <th class="text-left">Packungsgröße</th>
                      <th class="text-left">PZN</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(medication, index) in data.medications
                        .further_medications"
                      :key="index"
                    >
                      <td class="text-left">
                        {{ medication.name }}
                      </td>
                      <td class="text-left">
                        {{ medication.dosage }}
                      </td>
                      <td class="text-left">
                        {{ medication.active_ingredient }}
                      </td>
                      <td
                        v-if="
                          medication.intakes.selected ===
                          'zu konkreten Uhrzeiten'
                        "
                        class="text-left"
                      >
                        <v-table>
                          <tr>
                            <td
                              v-for="(time, idx) in medication.intakes
                                .intake_dates"
                              :key="idx"
                              class="text-left"
                            >
                              {{ time }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              v-for="(amount, idx) in medication.intakes
                                .intake_amounts"
                              :key="idx"
                            >
                              {{ amount }}
                            </td>
                          </tr>
                        </v-table>
                      </td>
                      <td
                        v-else-if="
                          medication.intakes.selected ===
                          'Einnahmeschema (X-X-X-X)'
                        "
                        class="text-left"
                      >
                        <v-table>
                          <tr>
                            <td class="text-left">Morgens</td>
                            <td class="text-left">Mittags</td>
                            <td class="text-left">Abends</td>
                            <td class="text-left">Zur Nacht</td>
                          </tr>
                          <tr>
                            <td
                              v-for="(morning, idx) in medication.intakes
                                .intake_schema.morning"
                              :key="idx"
                              class="text-left"
                            >
                              {{ morning }}
                            </td>
                            <td
                              v-for="(noon, idx) in medication.intakes
                                .intake_schema.noon"
                              :key="idx"
                            >
                              {{ noon }}
                            </td>
                            <td
                              v-for="(evening, idx) in medication.intakes
                                .intake_schema.evening"
                              :key="idx"
                            >
                              {{ evening }}
                            </td>
                            <td
                              v-for="(night, idx) in medication.intakes
                                .intake_schema.night"
                              :key="idx"
                            >
                              {{ night }}
                            </td>
                          </tr>
                        </v-table>
                      </td>
                      <td v-else class="text-left">
                        Bei Bedarf - siehe Kommentar
                      </td>
                      <td class="text-left">
                        {{ medication.hints }}
                      </td>
                      <td class="text-left">
                        <v-table>
                          <tr>
                            <td class="text-left">Wirkstoff:</td>
                            <td class="text-left">
                              {{ medication.ac_ing_since }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">Dosis:</td>
                            <td class="text-left">
                              {{ medication.dosis_since }}
                            </td>
                          </tr>
                        </v-table>
                      </td>
                      <td class="text-left">
                        {{ medication.package_size }}
                      </td>
                      <td class="text-left">
                        {{ medication.pzn }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-row>

              <v-row v-if="data.medications.pump_therapies">
                <v-col>
                  <h5 class="mb-n2 mt-2">Pumpen</h5>
                </v-col>
              </v-row>
              <v-row v-if="data.medications.pump_therapies">
                <v-table>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">Pumpe</th>
                      <th class="text-left">Flussrate in mg oder ml</th>
                      <th class="text-left">Von</th>
                      <th class="text-left">Bis</th>
                      <th class="text-left">Bolus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(therapy, index) in data.medications
                        .pump_therapies"
                      :key="index"
                    >
                      <tr
                        v-for="(setting, idx) in therapy.settings.from"
                        :key="idx"
                      >
                        <td class="text-left">
                          {{ index + 1 }}
                        </td>
                        <td class="text-left">
                          <v-table>
                            <tr>
                              <td class="text-left">
                                {{ therapy.pump.selected }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                v-if="therapy.pump.selected_apo"
                                class="text-left"
                              >
                                ({{ therapy.pump.selected_apo }})
                              </td>
                            </tr>
                          </v-table>
                        </td>
                        <td class="text-left">
                          {{ therapy.settings.flow_rate[idx] }}
                        </td>
                        <td class="text-left">
                          {{ therapy.settings.from[idx] }}
                        </td>
                        <td class="text-left">
                          {{ therapy.settings.until[idx] }}
                        </td>
                        <td class="text-left">
                          {{ therapy.settings.bolus[idx] }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </v-table>
              </v-row>

              <v-row v-if="data.medications.brain_stimulations">
                <v-col>
                  <h5 class="mb-n2 mt-2">Tiefe Hirnstimulation</h5>
                </v-col>
              </v-row>
              <v-row v-if="data.medications.brain_stimulations">
                <v-table>
                  <thead>
                    <tr>
                      <th class="text-left">THS seit</th>
                      <th class="text-left">Provider</th>
                      <th class="text-left">Ansprechpartner</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(stimulation, index) in data.medications
                        .brain_stimulations"
                      :key="index"
                    >
                      <td class="text-left">
                        {{ stimulation.ths_since }}
                      </td>
                      <td class="text-left">
                        {{ stimulation.provider }}
                      </td>
                      <td class="text-left">
                        {{ stimulation.contact_person }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-row>

              <v-row
                v-if="
                  data.medications.uploads.file &&
                  data.medications.uploads.fileBlob
                "
              >
                <v-col>
                  <h5 class="mb-n2 mt-2">Medikationsdatei</h5>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  data.medications.uploads.file &&
                  data.medications.uploads.fileBlob
                "
              >
                <v-table>
                  <thead>
                    <tr>
                      <th class="text-left">Datei</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left">
                        <vue-pdf-embed
                          id="medication_file"
                          :source="data.medications.uploads.fileBlob"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-row>
      </v-col>
    </v-expansion-panels>

    <v-dialog v-model="dialog" persistent width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5">Medikationsplan erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" fast-fail @submit.prevent="uploadFile">
            <v-text-field
              :readonly="disabled"
              :rules="DateRule"
              v-model="creationDate"
              type="date"
              :max="new Date().toISOString().slice(0, -14)"
              label="Erstellungsdatum"
              @keydown="clearInput()"
            ></v-text-field>
            <br />
            <h3>Parkinson-Medikamente</h3>
            <div
              v-for="(field, index) in fields['parkinson_medications']"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    {{ field.active_ingredient }}
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.active_ingredient"
                          label="Wirkstoff"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.name"
                          label="Handelsname"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.dosage"
                          label="Stärke"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.package_size"
                          label="Packungsgröße"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.pzn"
                          label="PZN"
                          :rules="pznRulesParkMed"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.ac_ing_since"
                          label="Wirkstoff seit:"
                          :rules="mmyyRuleParkMed"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.dosis_since"
                          label="Dosis seit:"
                          :rules="mmyyRuleParkMed"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6"
                        ><p>Einnahmezeitpunkt und Menge</p>
                        <br
                      /></v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="10" class="mb-n6">
                        <v-select
                          v-model="field.intakes.selected"
                          :items="field.intakes.selects"
                          label="Einnahmeschema"
                          @update:model-value="
                            checkSelections('park_med', index)
                          "
                        ></v-select>
                        <v-row
                          class="mb-n6"
                          v-for="(item, idx) in field.intakes.intake_dates"
                          :key="idx"
                        >
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              v-model="field.intakes.intake_amounts[idx]"
                              label="Anzahl"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              :rules="durationRulesParkMed"
                              v-model="field.intakes.intake_dates[idx]"
                              label="Uhrzeit (HH:MM)"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="field.intakes.check_select"
                              @click="addItem('parkinson_medications', index)"
                              class="btn btn-primary"
                              prepend-icon="mdi-plus"
                            ></v-btn>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="
                                field.intakes.intake_dates.length > 1 &&
                                field.intakes.check_select
                              "
                              @click="
                                removeItem('parkinson_medications', index, idx)
                              "
                              class="btn btn-primary"
                              prepend-icon="mdi-minus"
                            ></v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <br /><br /><br />
                    <v-row>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.hints"
                          label="Hinweis/Kommentar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="removeField('parkinson_medications', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Parkinson-Medikament löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('parkinson_medications')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Parkinson-Medikamente hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>
            
            <h3>Sonstige Medikamente</h3>
            <div
              v-for="(field, index) in fields['further_medications']"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    {{ field.active_ingredient }}
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.active_ingredient"
                          label="Wirkstoff"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.name"
                          label="Handelsname"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.dosage"
                          label="Stärke"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.package_size"
                          label="Packungsgröße"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.pzn"
                          label="PZN"
                          :rules="pznRulesFurthMed"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.ac_ing_since"
                          label="Wirkstoff seit:"
                          :rules="mmyyRuleFurthMed"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.dosis_since"
                          label="Dosis seit:"
                          :rules="mmyyRuleFurthMed"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6"
                        ><p>Einnahmezeitpunkt und Menge</p>
                        <br
                      /></v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="10" class="mb-n6">
                        <v-select
                          v-model="field.intakes.selected"
                          :items="field.intakes.selects"
                          label="Einnahmeschema"
                          @update:model-value="
                            checkSelections('further_med', index)
                          "
                        ></v-select>
                        <v-row
                          class="mb-n6"
                          v-for="(item, idx) in field.intakes.intake_dates"
                          :key="idx"
                        >
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              v-model="field.intakes.intake_amounts[idx]"
                              label="Anzahl"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              :rules="durationRulesFurthMed"
                              v-model="field.intakes.intake_dates[idx]"
                              label="Uhrzeit (HH:MM)"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="field.intakes.check_select"
                              @click="addItem('further_medications', index)"
                              class="btn btn-primary"
                              prepend-icon="mdi-plus"
                            ></v-btn>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="
                                field.intakes.intake_dates.length > 1 &&
                                field.intakes.check_select
                              "
                              @click="
                                removeItem('further_medications', index, idx)
                              "
                              class="btn btn-primary"
                              prepend-icon="mdi-minus"
                            ></v-btn>
                          </v-col>
                        </v-row>
                        <v-row
                          class="mb-n6"
                          v-for="(item, idx) in field.intakes.intake_schema
                            .morning"
                          :key="idx"
                        >
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.morning[idx]"
                              label="Morgens"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.noon[idx]"
                              label="Mittags"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.evening[idx]"
                              label="Abends"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.night[idx]"
                              label="Nachts"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <!--
                          <v-col class="mb-n6">
                              <v-btn v-if="field.intakes.check_select_schema" @click="addItem('further_medications', index)" class="btn btn-primary"
                                  prepend-icon="mdi-plus"></v-btn>
                          </v-col>
                          <v-col class="mb-n6">
                              <v-btn v-if="field.intakes.intake_schema.morning.length > 1 && field.intakes.check_select_schema" 
                                  @click="removeItem('further_medications', index, idx)" class="btn btn-primary" prepend-icon="mdi-minus"></v-btn>
                          </v-col>
                          -->
                        </v-row>
                      </v-col>
                    </v-row>
                    <br /><br /><br />
                    <v-row>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.hints"
                          label="Hinweis/Kommentar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="removeField('further_medications', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Sonstiges Medikament löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('further_medications')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Sonstiges Medikament hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>

            <h3>Pumpentherapie</h3>
            <div
              v-for="(field, index) in fields['pump_therapies']"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    Neue Pumpentherapie
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col>
                        <v-select
                          v-model="field.pump.selected"
                          :items="field.pump.selects"
                          label="Pumpenart"
                          @update:model-value="checkSelections('pump', index)"
                        ></v-select>

                        <v-select
                          v-if="field.pump.check_select"
                          v-model="field.pump.selected_apo"
                          :items="field.pump.selects_apo"
                          label="Apomorphin-Pumpe"
                          @update:model-value="checkSelections('pump', index)"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6"
                        ><p>Einstellungen</p>
                        <br
                      /></v-col>
                    </v-row>
                    <v-row
                      class="mb-n6"
                      v-for="(item, idx) in field.settings.flow_rate"
                      :key="idx"
                    >
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.settings.flow_rate[idx]"
                          label="Flussrate in mg oder ml"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          :rules="durationRulesPump"
                          v-model="field.settings.from[idx]"
                          label="von: (HH:MM)"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          :rules="durationRulesPump"
                          v-model="field.settings.until[idx]"
                          label="bis: (HH:MM)"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.settings.bolus[idx]"
                          label="Boluseinstellungen"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-btn
                          @click="addItem('pump_therapies', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-plus"
                        ></v-btn>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-btn
                          v-if="field.settings.flow_rate.length > 1"
                          @click="removeItem('pump_therapies', index, idx)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                        ></v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="removeField('pump_therapies', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Pumpentherapie löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('pump_therapies')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Pumpentherapie hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>

            <h3>Tiefe Hirnstimulation</h3>
            <div
              v-for="(field, index) in fields['brain_stimulations']"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    Neue tiefe Hirnstimulation
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          :rules="DateRule"
                          v-model="field.ths_since"
                          :max="new Date().toISOString().slice(0, -14)"
                          type="date"
                          label="THS seit:"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.provider"
                          label="Provider"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.contact_person"
                          label="Ansprechpartner (Homecare Service)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="removeField('brain_stimulations', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Tiefe Hirnstimulation löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('brain_stimulations')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Tiefe Hirnstimulation hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>

            <h3>Medikationsplan hochladen</h3>
            <div>
              <!-- <v-form ref="form" fast-fail @submit.prevent="uploadFile">
                                Medikationsplan-Upload -->
              <v-sheet
                border="md"
                rounded="lg"
                width="100%"
                class="my-sheet pa-4 text-left mx-auto mb-2 mr-2"
              >
                <v-row class="mb-n6">
                  <v-file-input
                    v-model="file"
                    accept=".pdf"
                    :rules="fileRules"
                    label="PDF-Upload"
                  ></v-file-input>
                </v-row>
                <v-row>
                  <v-col v-if="file" cols="12" class="text-right">
                    <v-btn
                      @click="removeField('uploads', index)"
                      class="btn btn-primary"
                      prepend-icon="mdi-minus"
                      >Upload löschen</v-btn
                    >
                  </v-col>
                </v-row>
              </v-sheet>
              <!-- </v-form> -->
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>

            <v-row>
              <v-col>
                <v-btn v-if="!disabled" type="submit" block class="mt-2"
                  >Medikationsplan speichern</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="dialog = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- refresh an existing medication -->
    <v-dialog v-model="dialog_refresh" persistent width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5">Medikationsplan aktualisieren</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" fast-fail @submit.prevent="uploadFile">
            <v-row>
              <v-col class="mb-n6">
                <v-text-field
                  :readonly="disabled"
                  :rules="DateRule"
                  v-model="creationDate"
                  type="date"
                  :max="new Date().toISOString().slice(0, -14)"
                  label="Erstellungsdatum"
                  @keydown="clearInput()"
                ></v-text-field>
              </v-col>
              <v-col class="mb-n6">
                <v-text-field
                  :readonly="disabled"
                  :rules="notEmptyRule"
                  v-model="reason"
                  label="Grund für Aktualisierung"
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <h3>Parkinson-Medikamente</h3>
            <div
              v-for="(field, index) in medication_to_refresh_data.medications[
                'parkinson_medications'
              ]"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    {{ field.active_ingredient }}
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.active_ingredient"
                          label="Wirkstoff"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.name"
                          label="Handelsname"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.dosage"
                          label="Stärke"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.package_size"
                          label="Packungsgröße"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.pzn"
                          label="PZN"
                          :rules="pznRulesParkMed"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.ac_ing_since"
                          label="Wirkstoff seit:"
                          :rules="mmyyRuleParkMed"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.dosis_since"
                          label="Dosis seit:"
                          :rules="mmyyRuleParkMed"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6"
                        ><p>Einnahmezeitpunkt und Menge</p>
                        <br
                      /></v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="10" class="mb-n6">
                        <v-select
                          v-model="field.intakes.selected"
                          :items="field.intakes.selects"
                          label="Einnahmeschema"
                          @update:model-value="
                            checkSelections('park_med', index)
                          "
                        ></v-select>
                        <v-row
                          class="mb-n6"
                          v-for="(item, idx) in field.intakes.intake_dates"
                          :key="idx"
                        >
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              v-model="field.intakes.intake_amounts[idx]"
                              label="Anzahl"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              :rules="durationRulesParkMed"
                              v-model="field.intakes.intake_dates[idx]"
                              label="Uhrzeit (HH:MM)"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="field.intakes.check_select"
                              @click="addItem('parkinson_medications', index)"
                              class="btn btn-primary"
                              prepend-icon="mdi-plus"
                            ></v-btn>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="
                                field.intakes.intake_dates.length > 1 &&
                                field.intakes.check_select
                              "
                              @click="
                                removeItem('parkinson_medications', index, idx)
                              "
                              class="btn btn-primary"
                              prepend-icon="mdi-minus"
                            ></v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <br /><br /><br />
                    <v-row>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.hints"
                          label="Hinweis/Kommentar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="removeField('parkinson_medications', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Parkinson-Medikament löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('parkinson_medications')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Parkinson-Medikamente hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>
            
            <h3>Sonstige Medikamente</h3>
            <div
              v-for="(field, index) in medication_to_refresh_data.medications[
                'further_medications'
              ]"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    {{ field.active_ingredient }}
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.active_ingredient"
                          label="Wirkstoff"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.name"
                          label="Handelsname"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.dosage"
                          label="Stärke"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.package_size"
                          label="Packungsgröße"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.pzn"
                          label="PZN"
                          :rules="pznRulesFurthMed"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.ac_ing_since"
                          label="Wirkstoff seit:"
                          :rules="mmyyRuleFurthMed"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.dosis_since"
                          label="Dosis seit:"
                          :rules="mmyyRuleFurthMed"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6"
                        ><p>Einnahmezeitpunkt und Menge</p>
                        <br
                      /></v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="10" class="mb-n6">
                        <v-select
                          v-model="field.intakes.selected"
                          :items="field.intakes.selects"
                          label="Einnahmeschema"
                          @update:model-value="
                            checkSelections('further_med', index)
                          "
                        ></v-select>
                        <v-row
                          class="mb-n6"
                          v-for="(item, idx) in field.intakes.intake_dates"
                          :key="idx"
                        >
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              v-model="field.intakes.intake_amounts[idx]"
                              label="Anzahl"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              :rules="durationRulesFurthMed"
                              v-model="field.intakes.intake_dates[idx]"
                              label="Uhrzeit (HH:MM)"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="field.intakes.check_select"
                              @click="addItem('further_medications', index)"
                              class="btn btn-primary"
                              prepend-icon="mdi-plus"
                            ></v-btn>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="
                                field.intakes.intake_dates.length > 1 &&
                                field.intakes.check_select
                              "
                              @click="
                                removeItem('further_medications', index, idx)
                              "
                              class="btn btn-primary"
                              prepend-icon="mdi-minus"
                            ></v-btn>
                          </v-col>
                        </v-row>
                        <v-row
                          class="mb-n6"
                          v-for="(item, idx) in field.intakes.intake_schema
                            .morning"
                          :key="idx"
                        >
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.morning[idx]"
                              label="Morgens"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.noon[idx]"
                              label="Mittags"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.evening[idx]"
                              label="Abends"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.night[idx]"
                              label="Nachts"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <!--
                          <v-col class="mb-n6">
                              <v-btn v-if="field.intakes.check_select_schema" @click="addItem('further_medications', index)" class="btn btn-primary"
                                  prepend-icon="mdi-plus"></v-btn>
                          </v-col>
                          <v-col class="mb-n6">
                              <v-btn v-if="field.intakes.intake_schema.morning.length > 1 && field.intakes.check_select_schema" 
                                  @click="removeItem('further_medications', index, idx)" class="btn btn-primary" prepend-icon="mdi-minus"></v-btn>
                          </v-col>
                          -->
                        </v-row>
                      </v-col>
                    </v-row>
                    <br /><br /><br />
                    <v-row>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.hints"
                          label="Hinweis/Kommentar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="removeField('further_medications', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Sonstiges Medikament löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('further_medications')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Sonstiges Medikament hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>

            <h3>Pumpentherapie</h3>
            <div
              v-for="(field, index) in medication_to_refresh_data.medications[
                'pump_therapies'
              ]"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    Neue/zu aktualisierende Pumpentherapie
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col>
                        <v-select
                          v-model="field.pump.selected"
                          :items="field.pump.selects"
                          label="Pumpenart"
                          @update:model-value="checkSelections('pump', index)"
                        ></v-select>

                        <v-select
                          v-if="field.pump.check_select"
                          v-model="field.pump.selected_apo"
                          :items="field.pump.selects_apo"
                          label="Apomorphin-Pumpe"
                          @update:model-value="checkSelections('pump', index)"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6"
                        ><p>Einstellungen</p>
                        <br
                      /></v-col>
                    </v-row>
                    <v-row
                      class="mb-n6"
                      v-for="(item, idx) in field.settings.flow_rate"
                      :key="idx"
                    >
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.settings.flow_rate[idx]"
                          label="Flussrate in mg oder ml"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          :rules="durationRulesPump"
                          v-model="field.settings.from[idx]"
                          label="von: (HH:MM)"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          :rules="durationRulesPump"
                          v-model="field.settings.until[idx]"
                          label="bis: (HH:MM)"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.settings.bolus[idx]"
                          label="Boluseinstellungen"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-btn
                          @click="addItem('pump_therapies', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-plus"
                        ></v-btn>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-btn
                          v-if="field.settings.flow_rate.length > 1"
                          @click="removeItem('pump_therapies', index, idx)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                        ></v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="removeField('pump_therapies', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Pumpentherapie löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('pump_therapies')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Pumpentherapie hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>

            <h3>Tiefe Hirnstimulation</h3>
            <div
              v-for="(field, index) in medication_to_refresh_data.medications[
                'brain_stimulations'
              ]"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    Neue/zu aktualisierende tiefe Hirnstimulation
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          :rules="DateRule"
                          v-model="field.ths_since"
                          :max="new Date().toISOString().slice(0, -14)"
                          type="date"
                          label="THS seit:"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.provider"
                          label="Provider"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.contact_person"
                          label="Ansprechpartner (Homecare Service)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="removeField('brain_stimulations', index)"
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Tiefe Hirnstimulation löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('brain_stimulations')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Tiefe Hirnstimulation hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>

            Medikationsplan hochladen
            <div>
              <v-sheet
                border="md"
                rounded="lg"
                width="100%"
                class="my-sheet pa-4 text-left mx-auto mb-2 mr-2"
              >
                <v-row class="mb-n6">
                  <v-file-input
                    v-model="file"
                    accept=".pdf"
                    :rules="fileRules"
                    label="PDF-Upload"
                  ></v-file-input>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      @click="removeField('uploads', index)"
                      class="btn btn-primary"
                      prepend-icon="mdi-minus"
                      >Upload löschen</v-btn
                    >
                  </v-col>
                </v-row>
              </v-sheet>
              <v-divider class="mt-2 mb-2"></v-divider>
              <!-- </v-form> -->
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-row>
              <v-col>
                <v-btn v-if="!disabled" type="submit" block class="mt-2"
                  >Medikationsplan aktualisieren</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="dialog_refresh = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="discontinuedMedication" persistent width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5">Abgesetzte Medikamente</span>
        </v-card-title>
        <v-card-text>
          <p style="color: red">
            Hinweis: Beim Abspeichern von abgesetzten Medikamenten wird ein
            Listeneintrag in der Übersicht mit den Daten vom letzten
            Medikationsplan angelegt. Die Angaben zu abgesetzten Medikamenten
            werden dort jedoch nicht angezeigt. Für die Einsicht über diese
            Angaben, klicken Sie in Zukunft einfach auf den Button "abgesetzte
            Medikamente".
          </p>
          <!-- uploadFile will go through 'if file !== undefined' with false, hence it will trigger the addMedicationPlan func without uploading a file-->
          <v-form ref="form" fast-fail @submit.prevent="uploadFile">
            <h3>Abgesetzte Medikamente</h3>
            <div
              v-for="(field, index) in medication_to_refresh_data.medications[
                'discontinued_medications'
              ]"
              :key="index"
            >
              <v-expansion-panels variant="accordion">
                <v-expansion-panel
                  width="100%"
                  class="text-left mx-auto mb-2 mr-2"
                >
                  <v-expansion-panel-title>
                    Abgesetztes Medikament
                  </v-expansion-panel-title>
                  <v-expansion-panel-text eager>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.active_ingredient"
                          label="Wirkstoff"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.name"
                          label="Handelsname"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.dosage"
                          label="Stärke"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.package_size"
                          label="Packungsgröße"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mb-n6">
                        <v-text-field
                          v-model="field.pzn"
                          label="PZN"
                          :rules="pznRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row cols="6" class="mb-n6">
                      <v-col>
                        <v-text-field
                          :readonly="disabled"
                          :rules="DateRule"
                          v-model="field.discontinued_since"
                          type="date"
                          :max="new Date().toISOString().slice(0, -14)"
                          label="abgesetzt seit:"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col class="mb-n6"
                        ><p>Einnahmezeitpunkt und Menge</p>
                        <br
                      /></v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col cols="10" class="mb-n6">
                        <v-select
                          v-model="field.intakes.selected"
                          :items="field.intakes.selects"
                          label="Einnahmeschema"
                          @update:model-value="
                            checkSelections('disc_med', index)
                          "
                        ></v-select>
                        <v-row
                          class="mb-n6"
                          v-for="(item, idx) in field.intakes.intake_dates"
                          :key="idx"
                        >
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              v-model="field.intakes.intake_amounts[idx]"
                              label="Anzahl"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select"
                              :rules="durationRules"
                              v-model="field.intakes.intake_dates[idx]"
                              label="Uhrzeit (HH:MM)"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="field.intakes.check_select"
                              @click="addItem('disc_medications', index)"
                              class="btn btn-primary"
                              prepend-icon="mdi-plus"
                            ></v-btn>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-btn
                              v-if="
                                field.intakes.intake_dates.length > 1 &&
                                field.intakes.check_select
                              "
                              @click="
                                removeItem('disc_medications', index, idx)
                              "
                              class="btn btn-primary"
                              prepend-icon="mdi-minus"
                            ></v-btn>
                          </v-col>
                        </v-row>
                        <v-row
                          class="mb-n6"
                          v-for="(item, idx) in field.intakes.intake_schema
                            .morning"
                          :key="idx"
                        >
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.morning[idx]"
                              label="Morgens"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.noon[idx]"
                              label="Mittags"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.evening[idx]"
                              label="Abends"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col class="mb-n6">
                            <v-text-field
                              v-if="field.intakes.check_select_schema"
                              v-model="field.intakes.intake_schema.night[idx]"
                              label="Nachts"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <!--
                                                    <v-col class="mb-n6">
                                                        <v-btn v-if="field.intakes.check_select_schema" @click="addItem('disc_medications', index)" class="btn btn-primary"
                                                            prepend-icon="mdi-plus"></v-btn>
                                                    </v-col>
                                                    <v-col class="mb-n6">
                                                        <v-btn v-if="field.intakes.intake_schema.morning.length > 1 && field.intakes.check_select_schema" 
                                                            @click="removeItem('disc_medications', index, idx)" class="btn btn-primary" prepend-icon="mdi-minus"></v-btn>
                                                    </v-col>
                                                    -->
                        </v-row>
                      </v-col>
                    </v-row>
                    <br /><br /><br />
                    <v-row>
                      <v-col cols="6" class="mb-n6">
                        <v-text-field
                          v-model="field.hints"
                          label="Hinweis/Kommentar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="
                            removeField('discontinued_medications', index)
                          "
                          class="btn btn-primary"
                          prepend-icon="mdi-minus"
                          >Abgesetztes Medikament löschen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-btn
              @click="addField('discontinued_medications')"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Abgesetztes Medikament hinzufügen</v-btn
            >
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-row>
              <v-col>
                <v-btn v-if="!disabled" type="submit" block class="mt-2"
                  >Abgesetzte Medikamente speichern</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="discontinuedMedication = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" persistent width="1024">
        <v-card>
          <v-card-title>
            <span class="text-h5">Fehlerhafte Eingabe(n)</span>
          </v-card-title>
          <v-card-text>
            Bei (mindestens) einem Medikament entspricht das Format der Eingabe nicht der Vorgabe. Bitte korrigieren Sie
            die Eingabe. Folgende Fehlermeldung wurde generiert:
            <br>
            <br>
            <h2 class="text-center">{{ errorMsg[0].errorMessages[0] }}</h2>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="errorDialog = false"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";

export default {
  components: {
    VuePdfEmbed,
  },
  data: function () {
    return {
      dialog: false,
      dialog_refresh: false,
      medication_to_refresh_data: "",
      medication_to_refresh_index: "",
      patient_id: "",
      fields: {
        parkinson_medications: [],
        pump_therapies: [],
        brain_stimulations: [],
        further_medications: [],
        discontinued_medications: [],
        uploads: { date: "", file: "" },
      },
      fieldsTemplate: {
        parkinson_medications: [],
        pump_therapies: [],
        brain_stimulations: [],
        further_medications: [],
        discontinued_medications: [],
        uploads: { date: "", file: "" },
      },
      durationRules: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) || !value)
            return true;

          return "Uhrzeit muss im Format HH:MM angegeben werden";
        },
      ],
      durationRulesParkMed: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) || !value)
            return true;

          return "Uhrzeit muss im Format HH:MM angegeben werden (Parkinson-Medikament)";
        },
      ],
      durationRulesFurthMed: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) || !value)
            return true;

          return "Uhrzeit muss im Format HH:MM angegeben werden (sonstiges Medikament)";
        },
      ],
      durationRulesPump: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) || !value)
            return true;

          return "Uhrzeit muss im Format HH:MM angegeben werden (Pumpentherapie)";
        },
      ],
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
      ],
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Sie müssen einen Grund für die Aktualisierung angeben";
        },
      ],
      DateRule: [
        (value) => {
          if (value || !value) return true;

          return "Sie müssen ein Datum angeben";
        },
      ],
      pznRulesParkMed: [
        (value) => {
          if (/^[0-9]*$/.test(value) || !value) return true;

          return "PZN für Parkinsonmedikamente darf nur Zahlen enthalten";
        },
      ],
      pznRulesFurthMed: [
        (value) => {
          if (/^[0-9]*$/.test(value) || !value) return true;

          return "PZN für sonstige Medikamente darf nur Zahlen enthalten";
        },
      ],
      mmyyRuleParkMed: [
        (value) => {
          if (/^([0-1][0-9])-[0-9][0-9][0-9][0-9]$/.test(value) || !value)
            return true;

          return "Sie müssen Monat und Jahr im Format MM-YYYY angeben (Parkinson-Medikament)";
        },
      ],
      mmyyRuleFurthMed: [
        (value) => {
          if (/^([0-1][0-9])-[0-9][0-9][0-9][0-9]$/.test(value) || !value)
            return true;

          return "Sie müssen Monat und Jahr im Format MM-YYYY angeben (sonstiges Medikament)";
        },
      ],
      creationDate: new Date().toISOString().slice(0, -14),
      reason: "",
      file: undefined,
      loading: true,
      medicationPlans: {},
      panel: [0],
      discontinuedMedication: false,
      errorDialog: false,
      errorMsg: '',
    };
  },

  created() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
    this.fetchMedicationPlans();
  },

  methods: {
    addField: function (section) {
      if (this.dialog) {
        switch (section) {
          case "parkinson_medications":
            this.fields[section].push({
              active_ingredient: "",
              name: "",
              dosage: "",
              package_size: "",
              pzn: "",
              ac_ing_since: "",
              dosis_since: "",
              intakes: {
                selects: ["nach Bedarf", "zu konkreten Uhrzeiten"],
                selected: [],
                check_select: false,
                intake_amounts: [""],
                intake_dates: [""],
              },
              hints: "",
            });
            break;
          case "pump_therapies":
            this.fields[section].push({
              pump: {
                selects: [
                  "Duodapa-Pumpe",
                  "Apomorphin-Pumpe",
                  "Lecig-Pumpe",
                  "s.c. Foslevodopa/Foscarbidopa",
                ],
                selected: "",
                check_select: false,
                selects_apo: [
                  "So-connect Pumpe",
                  "Cané-Pumpe",
                  "D-mine Pumpe",
                  "Apo-pod",
                ],
                selected_apo: "",
              },
              settings: {
                flow_rate: [""],
                from: [""],
                until: [""],
                bolus: [""],
              },
            });
            break;
          case "brain_stimulations":
            this.fields[section].push({
              ths_since: "",
              provider: "",
              contact_person: "",
            });
            break;
          case "further_medications":
            this.fields[section].push({
              active_ingredient: "",
              name: "",
              dosage: "",
              package_size: "",
              pzn: "",
              ac_ing_since: "",
              dosis_since: "",
              intakes: {
                selects: [
                  "nach Bedarf",
                  "zu konkreten Uhrzeiten",
                  "Einnahmeschema (X-X-X-X)",
                ],
                selected: [],
                check_select: false,
                intake_amounts: [""],
                intake_dates: [""],
                check_select_schema: false,
                intake_schema: {
                  morning: [""],
                  noon: [""],
                  evening: [""],
                  night: [""],
                },
              },
              hints: "",
            });
            break;
          case "discontinued_medications":
            this.fields[section].push({
              active_ingredient: "",
              name: "",
              dosage: "",
              package_size: "",
              pzn: "",
              discontinued_since: "",
              intakes: {
                selects: [
                  "nach Bedarf",
                  "zu konkreten Uhrzeiten",
                  "Einnahmeschema (X-X-X-X)",
                ],
                selected: [],
                check_select: false,
                intake_amounts: [""],
                intake_dates: [""],
                check_select_schema: false,
                intake_schema: {
                  morning: [""],
                  noon: [""],
                  evening: [""],
                  night: [""],
                },
              },
              hints: "",
            });
            break;
          case "uploads":
            this.fields[section].push({ date: "", file: "" });
        }
      } else {
        if (this.dialog_refresh || this.discontinuedMedication) {
          switch (section) {
            case "parkinson_medications":
              this.medication_to_refresh_data.medications[section].push({
                active_ingredient: "",
                name: "",
                dosage: "",
                package_size: "",
                pzn: "",
                ac_ing_since: "",
                dosis_since: "",
                intakes: {
                  selects: ["nach Bedarf", "zu konkreten Uhrzeiten"],
                  selected: [],
                  check_select: false,
                  intake_amounts: [""],
                  intake_dates: [""],
                },
                hints: "",
              });
              break;
            case "pump_therapies":
              this.medication_to_refresh_data.medications[section].push({
                pump: {
                  selects: [
                    "Duodapa-Pumpe",
                    "Apomorphin-Pumpe",
                    "Lecig-Pumpe",
                    "s.c. Foslevodopa/Foscarbidopa",
                  ],
                  selected: "",
                  check_select: false,
                  selects_apo: [
                    "So-connect Pumpe",
                    "Cané-Pumpe",
                    "D-mine Pumpe",
                    "Apo-pod",
                  ],
                  selected_apo: "",
                },
                settings: {
                  flow_rate: [""],
                  from: [""],
                  until: [""],
                  bolus: [""],
                },
              });
              break;
            case "brain_stimulations":
              this.medication_to_refresh_data.medications[section].push({
                ths_since: "",
                provider: "",
                contact_person: "",
              });
              break;
            case "further_medications":
              this.medication_to_refresh_data.medications[section].push({
                active_ingredient: "",
                name: "",
                dosage: "",
                package_size: "",
                pzn: "",
                ac_ing_since: "",
                dosis_since: "",
                intakes: {
                  selects: [
                    "nach Bedarf",
                    "zu konkreten Uhrzeiten",
                    "Einnahmeschema (X-X-X-X)",
                  ],
                  selected: [],
                  check_select: false,
                  intake_amounts: [""],
                  intake_dates: [""],
                  check_select_schema: false,
                  intake_schema: {
                    morning: [""],
                    noon: [""],
                    evening: [""],
                    night: [""],
                  },
                },
                hints: "",
              });
              break;
            case "discontinued_medications":
              this.medication_to_refresh_data.medications[section].push({
                active_ingredient: "",
                name: "",
                dosage: "",
                package_size: "",
                pzn: "",
                discontinued_since: "",
                intakes: {
                  selects: [
                    "nach Bedarf",
                    "zu konkreten Uhrzeiten",
                    "Einnahmeschema (X-X-X-X)",
                  ],
                  selected: [],
                  check_select: false,
                  intake_amounts: [""],
                  intake_dates: [""],
                  check_select_schema: false,
                  intake_schema: {
                    morning: [""],
                    noon: [""],
                    evening: [""],
                    night: [""],
                  },
                },
                hints: "",
              });
              break;
            case "uploads":
              this.fields[section].push({ date: "", file: "" });
          }
        }
      }
    },

    removeField: function (section, index) {
      console.log(section);
      if (this.dialog) {
        this.fields[section].splice(index, 1);
      } else {
        if (this.dialog_refresh || this.discontinuedMedication) {
          this.medication_to_refresh_data.medications[section].splice(index, 1);
        }
      }
    },

    fetchMedicationPlans: function () {
      this.$axios
        .get("/get_medication_plans/" + this.patient_id)
        .then(
          (response) => {
            console.log(response);
            this.medicationPlans = response.data;
            if (!this.helpers.isEmpty(this.medicationPlans)) {
              this.medicationPlans = Object.values(this.medicationPlans).sort(
                function (a, b) {
                  return new Date(b.plan_datetime) - new Date(a.plan_datetime);
                }
              );
            }
            console.log(this.medicationPlans);
          },
          () => {}
        )
        .then(
          () => {
            this.loading = false;
            Object.values(this.medicationPlans).forEach((element) => {
              if ("uploads" in element.medications) {
                fetch(
                  process.env.VUE_APP_BACKEND_BASE_URL +
                    element.medications.uploads.file,
                  {
                    headers: {
                      Authorization: "Bearer " + this.keycloak.token,
                    },
                  }
                )
                  .then((response) => response.blob())
                  .then((blob) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                      var base64data = reader.result;
                      element.medications.uploads.fileBlob = base64data;
                    };
                  })
                  .catch((error) => console.error("Error:", error));
              }
            });
          },
          () => {}
        );
    },

    addMedicationPlan: function () {
      const userInfo = this.helpers.getUserInfoFromToken(this.keycloak.token);

      let medication_plan = {
        patient_id: this.patient_id,
        user_id: userInfo.user_name,
        plan_datetime: new Date().toISOString(),
        creationDate: this.creationDate,
        reason: this.reason,
        medications: {},
      };

      if (this.dialog) {
        medication_plan.medications = this.fields;
      } else {
        if (this.dialog_refresh || this.discontinuedMedication) {
          console.log(this.fields);
          medication_plan.medications = this.fields;
        }
      }

      this.$axios
        .post("/medication_plan/", medication_plan)
        .then((response) => {
          if ("success" in response.data) {
            console.log("MedicationPlan created");
          }
        })
        .then(() => {
          this.dialog_refresh = false;
          this.discontinuedMedication = false;
          this.dialog = false;
          this.fetchMedicationPlans();
        });
    },

    uploadFile: function () {
      this.$refs.form.validate().then((status) => {
        if (status.valid === true) {
          const ruleResult = this.fileRules[0](this.file);
          console.log(ruleResult);
          if (this.file !== undefined) {
            if (ruleResult == true) {
              let blob = this.file[0];
              let filePath = "";
              let newFile = new File(
                [blob],
                "MedicationPlanFile_" +
                  this.patient_id +
                  Math.floor(new Date().getTime() / 1000) +
                  ".pdf",
                { type: blob.type }
              );
              let formData = new FormData();
              formData.append("file", newFile);

              this.$axios
                .post("/upload/", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(
                  (response) => {
                    if ("error" in response.data) {
                      console.log(response.data);
                      return;
                    } else {
                      console.log("File Upload successfull");
                      filePath = response.data.success.path;
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                )
                .then(() => {
                  if (this.dialog) {
                    this.fields.uploads.date = new Date();
                    this.fields.uploads.file = filePath;
                  } else {
                    if (this.dialog_refresh) {
                      this.fields.uploads.date = new Date();
                      this.fields.uploads.file = filePath;
                    }
                  }
                })
                .then(() => {
                  this.addMedicationPlan();
                })
                .then(() => {
                  this.dialog_refresh = false;
                  this.discontinuedMedication = false;
                  this.dialog = false;
                  this.fetchMedicationPlans();
                });
            } else {
              console.log("else");
              this.file = null;
              this.fetchMedicationPlans();
            }
          } else {
            this.addMedicationPlan();
            this.dialog_refresh = false;
            this.discontinuedMedication = false;
            this.dialog = false;
            this.fetchMedicationPlans();
          }
        } else {
          let obj = JSON.parse(JSON.stringify(status.errors))
          this.errorMsg = obj
          this.errorDialog = true;
        }
      });
    },

    checkSelections: function (section, index) {
      if (this.dialog_refresh || this.discontinuedMedication) {
        if (section === "park_med") {
          if (
            this.medication_to_refresh_data.medications.parkinson_medications[
              index
            ].intakes.selected.includes("zu konkreten Uhrzeiten")
          ) {
            this.medication_to_refresh_data.medications.parkinson_medications[
              index
            ].intakes.check_select = true;
          } else {
            this.medication_to_refresh_data.medications.parkinson_medications[
              index
            ].intakes.check_select = false;
          }
        } else if (section === "pump") {
          if (
            this.medication_to_refresh_data.medications.pump_therapies[
              index
            ].pump.selected.includes("Apomorphin-Pumpe")
          ) {
            this.medication_to_refresh_data.medications.pump_therapies[
              index
            ].pump.check_select = true;
          } else {
            this.medication_to_refresh_data.medications.pump_therapies[
              index
            ].pump.check_select = false;
          }
        } else if (section === "further_med") {
          if (
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.selected.includes("zu konkreten Uhrzeiten")
          ) {
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select = true;
          } else if (
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.selected.includes("Einnahmeschema (X-X-X-X)")
          ) {
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select_schema = true;
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select = false;
          } else {
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select = false;
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select_schema = false;
          }
        } else if (section === "disc_med") {
          if (
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.selected.includes("zu konkreten Uhrzeiten")
          ) {
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.check_select = true;
          } else if (
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.selected.includes("Einnahmeschema (X-X-X-X)")
          ) {
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.check_select_schema = true;
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.check_select = false;
          } else {
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.check_select = false;
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.check_select_schema = false;
          }
        }
      } else {
        if (section === "park_med") {
          if (
            this.fields.parkinson_medications[index].intakes.selected.includes(
              "zu konkreten Uhrzeiten"
            )
          ) {
            this.fields.parkinson_medications[
              index
            ].intakes.check_select = true;
          } else {
            this.fields.parkinson_medications[
              index
            ].intakes.check_select = false;
          }
        } else if (section === "pump") {
          if (
            this.fields.pump_therapies[index].pump.selected.includes(
              "Apomorphin-Pumpe"
            )
          ) {
            this.fields.pump_therapies[index].pump.check_select = true;
          } else {
            this.fields.pump_therapies[index].pump.check_select = false;
          }
        } else if (section === "further_med") {
          if (
            this.fields.further_medications[index].intakes.selected.includes(
              "zu konkreten Uhrzeiten"
            )
          ) {
            this.fields.further_medications[index].intakes.check_select = true;
          } else if (
            this.fields.further_medications[index].intakes.selected.includes(
              "Einnahmeschema (X-X-X-X)"
            )
          ) {
            this.fields.further_medications[
              index
            ].intakes.check_select_schema = true;
            this.fields.further_medications[index].intakes.check_select = false;
          } else {
            this.fields.further_medications[index].intakes.check_select = false;
            this.fields.further_medications[
              index
            ].intakes.check_select_schema = false;
          }
        } else if (section === "disc_med") {
          if (
            this.fields.discontinued_medications[
              index
            ].intakes.selected.includes("zu konkreten Uhrzeiten")
          ) {
            this.fields.discontinued_medications[
              index
            ].intakes.check_select = true;
          } else if (
            this.fields.discontinued_medications[
              index
            ].intakes.selected.includes("Einnahmeschema (X-X-X-X)")
          ) {
            this.fields.discontinued_medications[
              index
            ].intakes.check_select_schema = true;
            this.fields.discontinued_medications[
              index
            ].intakes.check_select = false;
          } else {
            this.fields.discontinued_medications[
              index
            ].intakes.check_select = false;
            this.fields.discontinued_medications[
              index
            ].intakes.check_select_schema = false;
          }
        }
      }
    },

    addItem: function (item, index) {
      if (this.dialog_refresh || this.discontinuedMedication) {
        if (item === "parkinson_medications") {
          this.medication_to_refresh_data.medications.parkinson_medications[
            index
          ].intakes.intake_amounts.push("");
          this.medication_to_refresh_data.medications.parkinson_medications[
            index
          ].intakes.intake_dates.push("");
        } else if (item === "pump_therapies") {
          this.medication_to_refresh_data.medications.pump_therapies[
            index
          ].settings.flow_rate.push("");
          this.medication_to_refresh_data.medications.pump_therapies[
            index
          ].settings.from.push("");
          this.medication_to_refresh_data.medications.pump_therapies[
            index
          ].settings.until.push("");
          this.medication_to_refresh_data.medications.pump_therapies[
            index
          ].settings.bolus.push("");
        } else if (item === "further_medications") {
          if (
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select === true
          ) {
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_amounts.push("");
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_dates.push("");
          } else if (
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select_schema === true
          ) {
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_schema.morning.push("");
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_schema.noon.push("");
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_schema.evening.push("");
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_schema.night.push("");
          }
        } else if (item === "disc_medications") {
          if (
            this.medication_to_refresh_data.medications
              .discontinued_medications[index].intakes.check_select === true
          ) {
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_amounts.push("");
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_dates.push("");
          } else if (
            this.medication_to_refresh_data.medications
              .discontinued_medications[index].intakes.check_select_schema ===
            true
          ) {
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_schema.morning.push("");
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_schema.noon.push("");
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_schema.evening.push("");
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_schema.night.push("");
          }
        }
      } else {
        if (item === "parkinson_medications") {
          this.fields.parkinson_medications[index].intakes.intake_amounts.push(
            ""
          );
          this.fields.parkinson_medications[index].intakes.intake_dates.push(
            ""
          );
        } else if (item === "pump_therapies") {
          this.fields.pump_therapies[index].settings.flow_rate.push("");
          this.fields.pump_therapies[index].settings.from.push("");
          this.fields.pump_therapies[index].settings.until.push("");
          this.fields.pump_therapies[index].settings.bolus.push("");
        } else if (item === "further_medications") {
          if (
            this.fields.further_medications[index].intakes.check_select === true
          ) {
            this.fields.further_medications[index].intakes.intake_amounts.push(
              ""
            );
            this.fields.further_medications[index].intakes.intake_dates.push(
              ""
            );
          } else if (
            this.fields.further_medications[index].intakes
              .check_select_schema === true
          ) {
            this.fields.further_medications[
              index
            ].intakes.intake_schema.morning.push("");
            this.fields.further_medications[
              index
            ].intakes.intake_schema.noon.push("");
            this.fields.further_medications[
              index
            ].intakes.intake_schema.evening.push("");
            this.fields.further_medications[
              index
            ].intakes.intake_schema.night.push("");
          }
          // most likely not needed for the creation
        } else if (item === "disc_medications") {
          if (
            this.fields.medications.discontinued_medications[index].intakes
              .check_select === true
          ) {
            this.fields.medications.discontinued_medications[
              index
            ].intakes.intake_amounts.push("");
            this.fields.medications.discontinued_medications[
              index
            ].intakes.intake_dates.push("");
          } else if (
            this.fields.medications.discontinued_medications[index].intakes
              .check_select_schema === true
          ) {
            this.fields.medications.discontinued_medications[
              index
            ].intakes.intake_schema.morning.push("");
            this.fields.medications.discontinued_medications[
              index
            ].intakes.intake_schema.noon.push("");
            this.fields.medications.discontinued_medications[
              index
            ].intakes.intake_schema.evening.push("");
            this.fields.medications.discontinued_medications[
              index
            ].intakes.intake_schema.night.push("");
          }
        }
      }
    },

    removeItem: function (item, index, idx) {
      if (this.dialog_refresh || this.discontinuedMedication) {
        if (item === "parkinson_medications") {
          this.medication_to_refresh_data.medications.parkinson_medications[
            index
          ].intakes.intake_amounts.splice(idx, 1);
          this.fmedication_to_refresh_data.medications.parkinson_medications[
            index
          ].intakes.intake_dates.splice(idx, 1);
        } else if (item === "pump_therapies") {
          this.medication_to_refresh_data.medications.pump_therapies[
            index
          ].settings.flow_rate.splice(idx, 1);
          this.medication_to_refresh_data.medications.pump_therapies[
            index
          ].settings.from.splice(idx, 1);
          this.medication_to_refresh_data.medications.pump_therapies[
            index
          ].settings.until.splice(idx, 1);
          this.medication_to_refresh_data.medications.pump_therapies[
            index
          ].settings.bolus.splice(idx, 1);
        } else if (item === "further_medications") {
          if (
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select === true
          ) {
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_amounts.splice(idx, 1);
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_dates.splice(idx, 1);
          } else if (
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.check_select_schema === true
          ) {
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_schema.morning.splice(idx, 1);
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_schema.noon.splice(idx, 1);
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_schema.evening.splice(idx, 1);
            this.medication_to_refresh_data.medications.further_medications[
              index
            ].intakes.intake_schema.night.splice(idx, 1);
          }
        } else if (item === "disc_medications") {
          if (
            this.medication_to_refresh_data.medications
              .discontinued_medications[index].intakes.check_select === true
          ) {
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_amounts.splice(idx, 1);
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_dates.splice(idx, 1);
          } else if (
            this.medication_to_refresh_data.medications
              .discontinued_medications[index].intakes.check_select_schema ===
            true
          ) {
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_schema.morning.splice(idx, 1);
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_schema.noon.splice(idx, 1);
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_schema.evening.splice(idx, 1);
            this.medication_to_refresh_data.medications.discontinued_medications[
              index
            ].intakes.intake_schema.night.splice(idx, 1);
          }
        }
      } else {
        if (item === "parkinson_medications") {
          this.fields.parkinson_medications[
            index
          ].intakes.intake_amounts.splice(idx, 1);
          this.fields.parkinson_medications[index].intakes.intake_dates.splice(
            idx,
            1
          );
        } else if (item === "pump_therapies") {
          this.fields.pump_therapies[index].settings.flow_rate.splice(idx, 1);
          this.fields.pump_therapies[index].settings.from.splice(idx, 1);
          this.fields.pump_therapies[index].settings.until.splice(idx, 1);
          this.fields.pump_therapies[index].settings.bolus.splice(idx, 1);
        } else if (item === "further_medications") {
          if (
            this.fields.further_medications[index].intakes.check_select === true
          ) {
            this.fields.further_medications[
              index
            ].intakes.intake_amounts.splice(idx, 1);
            this.fields.further_medications[index].intakes.intake_dates.splice(
              idx,
              1
            );
          } else if (
            this.fields.further_medications[index].intakes
              .check_select_schema === true
          ) {
            this.fields.further_medications[
              index
            ].intakes.intake_schema.morning.splice(idx, 1);
            this.fields.further_medications[
              index
            ].intakes.intake_schema.noon.splice(idx, 1);
            this.fields.further_medications[
              index
            ].intakes.intake_schema.evening.splice(idx, 1);
            this.fields.further_medications[
              index
            ].intakes.intake_schema.night.splice(idx, 1);
          }
          // most likely not needed for the creation
        } else if (item === "disc_medications") {
          if (
            this.fields.discontinued_medications[index].intakes.check_select ===
            true
          ) {
            this.fields.discontinued_medications[
              index
            ].intakes.intake_amounts.splice(idx, 1);
            this.fields.discontinued_medications[
              index
            ].intakes.intake_dates.splice(idx, 1);
          } else if (
            this.fields.discontinued_medications[index].intakes
              .check_select_schema === true
          ) {
            this.fields.discontinued_medications[
              index
            ].intakes.intake_schema.morning.splice(idx, 1);
            this.fields.discontinued_medications[
              index
            ].intakes.intake_schema.noon.splice(idx, 1);
            this.fields.discontinued_medications[
              index
            ].intakes.intake_schema.evening.splice(idx, 1);
            this.fields.discontinued_medications[
              index
            ].intakes.intake_schema.night.splice(idx, 1);
          }
        }
      }
    },

    checkIfOld: function (fMedicationPlans) {
      for (let fmp of fMedicationPlans) {
        console.log(fmp.plan_datetime);
        if (new Date(fmp.plan_datetime) > new Date(2024, 3, 1)) {
          return true;
        }
      }
      return false;
    },

    clearInput: function() {
      this.dateOfProcedure = ""
    }
  },

  mounted() {
    this.fetchMedicationPlans();
  },
};
</script>
