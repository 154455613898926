<template>
  <v-container>
    <v-form ref="form" :disabled="submitted" fast-fail @submit.prevent="addDocumentation">
      <v-text-field
        :readonly="disabled"
        :rules="notEmptyRule"
        v-model="dateOfProcedure"
        type="date"
        :max="new Date().toISOString().slice(0, -14)"
        label="Datum der Maßnahme"
        @keydown="clearInput()"
      ></v-text-field>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRules"
        label="Dauer des Telefonats (HH:MM)"
      ></v-text-field>

      <!-- Besprechung einer Verschlechterung/ Veränderung -->
      <v-label class="text-left">Grund für das Telefonat</v-label>
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.status"
        label="Besprechung einer Verschlechterung/ Veränderung"
      ></v-checkbox>
      <v-select
        v-show="reasonsOfCall.worsening.status"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.selected"
        :items="reasonsOfCall.worsening.selects"
        label="Was hat sich verschlechtert/verändert?"
        multiple
        @update:model-value="checkSelections()"
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.worsening.status && reasonsOfCall.worsening.changeBool"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.change"
        label="andere Verschlechterungen/Veränderungen"
      ></v-textarea>
      <v-textarea
        v-show="reasonsOfCall.worsening.status"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-select
        v-show="reasonsOfCall.worsening.status"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.selected_measures"
        :items="reasonsOfCall.worsening.selects_measures"
        label="Maßnahme für Versorger"
        multiple
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.worsening.status"
        :readonly="disabled"
        v-model="reasonsOfCall.worsening.suggestions_patient"
        label="Ausgegebene Empfehlung für Patienten (Reminder: Nr. des TipSheet im Freitext dokumentieren!)"
      ></v-textarea>

      <!-- Kontaktwunsch der/ des Patient*in -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.patient_wish.status"
        label="Kontaktwunsch der/ des Patient*in"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.patient_wish.status"
        :readonly="disabled"
        v-model="reasonsOfCall.patient_wish.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-select
        v-show="reasonsOfCall.patient_wish.status"
        :readonly="disabled"
        v-model="reasonsOfCall.patient_wish.selected"
        :items="reasonsOfCall.patient_wish.selects"
        label="Maßnahme für Versorger"
        multiple
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.patient_wish.status"
        :readonly="disabled"
        v-model="reasonsOfCall.patient_wish.suggestions_patient"
        label="Ausgegebene Empfehlung für Patienten (Reminder: Nr. des TipSheet im Freitext dokumentieren!)"
      ></v-textarea>

      <!-- Besprechung einer unregelmäßigen Nutzung des Monitorings -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.non_use.status"
        label="Besprechung einer unregelmäßigen Nutzung des Monitorings"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.non_use.status"
        :readonly="disabled"
        v-model="reasonsOfCall.non_use.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-select
        v-show="reasonsOfCall.non_use.status"
        :readonly="disabled"
        v-model="reasonsOfCall.non_use.selected"
        :items="reasonsOfCall.non_use.selects"
        label="Maßnahme für Versorger"
        multiple
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.non_use.status"
        :readonly="disabled"
        v-model="reasonsOfCall.non_use.suggestions_patient"
        label="Ausgegebene Empfehlung für Patienten (Reminder: Nr. des TipSheet im Freitext dokumentieren!)"
      ></v-textarea>

      <!-- Nachbesprechung des Pflegekonsils -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.care_council.status"
        label="Nachbesprechung des Pflegekonsils"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.care_council.status"
        :readonly="disabled"
        v-model="reasonsOfCall.care_council.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-textarea
        v-show="reasonsOfCall.care_council.status"
        :readonly="disabled"
        v-model="reasonsOfCall.care_council.suggestions"
        label="Ausgegebene Empfehlung"
      ></v-textarea>

      <!-- Nachbesprechung zu Empfehlung/en -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.suggestions.status"
        label="Nachbesprechung zu Empfehlung/en"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.suggestions.status"
        :readonly="disabled"
        v-model="reasonsOfCall.suggestions.full_compiliant"
        label="Umgesetzte Empfehlungen"
      ></v-textarea>
      <v-textarea
        v-show="reasonsOfCall.suggestions.status"
        :readonly="disabled"
        v-model="reasonsOfCall.suggestions.moderate_compliant"
        label="Teilweise umgesetzte Empfehlungen"
      ></v-textarea>
      <v-textarea
        v-show="reasonsOfCall.suggestions.status"
        :readonly="disabled"
        v-model="reasonsOfCall.suggestions.non_compliant"
        label="Nicht umgesetzte Empfehlungen"
      ></v-textarea>

      <!-- Nachbesprechung des Krankenhausaufenthalt -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.hospitalization.status"
        label="Nachbesprechung des Krankenhausaufenthalt"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.hospitalization.status"
        :readonly="disabled"
        v-model="reasonsOfCall.hospitalization.reason_care"
        label="Datum, Behandlung und Grund für Hospitalisierung"
      ></v-textarea>
      <v-file-input
        v-show="!disabled & reasonsOfCall.hospitalization.status"
        v-model="dischargeLetter"
        :rules="fileRules"
        accept=".pdf"
        label="Entlass-/Arztbrief"
      ></v-file-input>

      <v-expansion-panels v-if="dischargeLetterPDF">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">Entlass-/Arztbrief</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed
              v-show="dischargeLetterPDF"
              :source="dischargeFileBlob"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- 2-monatliches Follow-up Telefonat -->
      <v-checkbox
        :readonly="disabled"
        v-model="reasonsOfCall.follow_up.status"
        label="2-monatliches Follow-up Telefonat"
      ></v-checkbox>
      <v-textarea
        v-show="reasonsOfCall.follow_up.status"
        :readonly="disabled"
        v-model="reasonsOfCall.follow_up.call_content"
        label="Gesprächsinhalt"
      ></v-textarea>
      <v-select
        v-show="reasonsOfCall.follow_up.status"
        :readonly="disabled"
        v-model="reasonsOfCall.follow_up.selected"
        :items="reasonsOfCall.follow_up.selects"
        label="Maßnahme für Versorger"
        multiple
      ></v-select>
      <v-textarea
        v-show="reasonsOfCall.follow_up.status"
        :readonly="disabled"
        v-model="reasonsOfCall.follow_up.suggestions_patient"
        label="Ausgegebene Empfehlung für Patienten (Reminder: Nr. des TipSheet im Freitext dokumentieren!)"
      ></v-textarea>

      <v-file-input
        v-show="!disabled"
        v-model="patientCallFile"
        :rules="fileRules"
        accept=".pdf"
        label="Dokumentation hochladen"
      ></v-file-input>
      <v-expansion-panels v-if="filePDF !== 'Not provided'">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">Dokumentationsdatei</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed v-show="filePDF" :source="fileBlob" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-textarea
        :readonly="disabled"
        v-model="reasonsOfCall.misc"
        label="Sonstige Inhalte"
      ></v-textarea>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import VueJWTDecode from "vue-jwt-decode";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "PatientCall",

  components: {
    VuePdfEmbed,
  },

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Kontakt Telenurse - Patient",
      type: "patient_call",
      filePDF: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "patientCallFile",
          ).value
        : "Not provided",
      fileBlob: undefined,
      patientCallFile: undefined,
      dischargeLetterPDF: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "reasons_of_call",
          ).value.hospitalization.discharge_letter_pdf
          ? this.document_data.content.find(
              (item) => item.property === "reasons_of_call",
            ).value.hospitalization.discharge_letter_pdf
          : undefined
        : undefined,
      billableProcedures: ["A06a"],
      dischargeFileBlob: undefined,
      reasonsOfCall: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "reasons_of_call",
          ).value
        : {
            worsening: {
              status: false,
              selects: [
                "Sensordaten",
                "Symptome im Tagebuch",
                "Wohlbefinden",
                "anderes",
              ],
              selected: [],
              selects_measures: [
                "Pflegeassessment notwendig",
                "Arztkonsultation notwendig",
              ],
              selected_measures: [],
              change: "",
              call_content: "",
              suggestions_patient: "",
              changeBool: false,
            },
            patient_wish: {
              status: false,
              selects: [
                "Pflegeassessment notwendig",
                "Arztkonsultation notwendig",
              ],
              selected: [],
              call_content: "",
              suggestions_patient: "",
            },
            non_use: {
              status: false,
              selects: [
                "Pflegeassessment notwendig",
                "Arztkonsultation notwendig",
              ],
              selected: [],
              call_content: "",
              suggestions_patient: "",
            },
            care_council: { status: false, call_content: "", suggestions: "" },
            suggestions: {
              status: false,
              suggestions: {
                full_compiliant: "",
                moderate_compliant: "",
                non_compliant: "",
              },
            },
            hospitalization: {
              status: false,
              reason_care: "",
              discharge_letter_pdf: "",
            },
            follow_up: {
              status: false,
              selects: [
                "Pflegeassessment notwendig",
                "Arztkonsultation notwendig",
              ],
              selected: [],
              call_content: "",
              suggestions_patient: "",
            },
            misc: "",
          },
      dischargeLetter: undefined,
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
      ],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration",
          ).value
        : "",
      durationRules: [
        (value) => {
          if (value) return true;

          return "Sie müssen eine Dauer angeben";
        },
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateOfProcedure: this.document_data
        ? this.document_data.treatment_date
        : "",
      disabled: this.disabled_field,
      submitted: false,
    };
  },

  methods: {
    addDocumentation: function () {
      this.$refs.form.validate().then(
        (status) => {
          if (status.valid === true) {
            this.submitted = true
            const token = this.keycloak.token;
            const decoded_token = VueJWTDecode.decode(token);
            let user_name = decoded_token.preferred_username;

            let patient_id = this.$route.params.id.split("&", 1)[0];

            let json_payload = {
              user_id: user_name,
              patient_id: patient_id,
              treatment_date: this.dateOfProcedure,
              documentation_date: new Date().toISOString(),
              treamtent_type: this.type,
              billable_procedures: this.billableProcedures,
              title: this.title,
              content: [
                { property: "reasons_of_call", value: this.reasonsOfCall },
                { property: "duration", value: this.duration },
                { property: "further", value: this.further },
              ],
            };

            let patientCallFilePath = undefined;
            // if patient_call PDF was uploaded do this part --> dischargePDF and patientCallPDF are two different files
            if (this.patientCallFile !== undefined && this.patientCallFile.length > 0) {
              let dischargeLetterPath = undefined;

              let blob = this.patientCallFile[0];
              let newFile = new File(
                [blob],
                "PatientCall_" +
                  patient_id +
                  "_" +
                  Math.floor(new Date().getTime() / 1000) +
                  ".pdf",
                { type: blob.type },
              );
              let formData = new FormData();
              formData.append("file", newFile);

              this.$axios
                .post("/upload/", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(
                  (response) => {
                    if ("error" in response.data) {
                      console.log(response.data);
                      return;
                    } else {
                      console.log("File Upload successfull");
                      console.log(response.data.success.path);
                      patientCallFilePath = response.data.success.path;
                      json_payload.content.push({
                        property: "patientCallFile",
                        value: patientCallFilePath,
                      });
                    }
                    // console.log("TreatmentDocument created")
                    // this.$emit('close-dialog');
                  },
                  (error) => {
                    console.log(error);
                  },
                )
                .then(() => {
                  // here check if the discharge letter was uploaded
                  if (this.dischargeLetter !== undefined && this.dischargeLetter.length > 0) {
                    let blob = this.dischargeLetter[0];
                    let newFile = new File(
                      [blob],
                      "DischargeLetter_" +
                        patient_id +
                        "_" +
                        Math.floor(new Date().getTime() / 1000) +
                        ".pdf",
                      { type: blob.type },
                    );
                    let formData = new FormData();
                    formData.append("file", newFile);

                    this.$axios
                      .post("/upload/", formData, {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      })
                      .then(
                        (response) => {
                          if ("error" in response.data) {
                            console.log(response.data);
                            return;
                          } else {
                            console.log("File Upload successfull");
                            console.log(response.data.success.path);
                            dischargeLetterPath = response.data.success.path;
                          }
                          // console.log("TreatmentDocument created")
                          // this.$emit('close-dialog');
                        },
                        (error) => {
                          console.log(error);
                        },
                      )
                      .then(() => {
                        this.reasonsOfCall.hospitalization.discharge_letter_pdf =
                          dischargeLetterPath;

                        this.$axios
                          .post("/treatment_documentation/", json_payload, {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          })
                          .then(
                            (response) => {
                              console.log(response);
                              if ("success" in response.data) {
                                console.log(response.data);
                                this.$emit("close-dialog");
                              } else {
                                console.log(response.data);
                              }
                            },
                            () => {
                              console.log("TreatmentDocument not created");
                            },
                          );
                      });
                  } else {
                    this.$axios
                      .post("/treatment_documentation/", json_payload, {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      })
                      .then(
                        (response) => {
                          console.log(response);
                          if ("success" in response.data) {
                            console.log(response.data);
                            this.$emit("close-dialog");
                          } else {
                            console.log(response.data);
                          }
                        },
                        () => {
                          console.log("TreatmentDocument not created");
                        },
                      );
                  }
                });
              // if no patient_call PDF was uploaded do this part --> dischargePDF and patientCallPDF are two different files
            } else {
              let dischargeLetterPath = undefined;
              json_payload.content.push({
                property: "patientCallFile",
                value: "Not provided",
              });

              // here check if the discharge letter was uploaded
              if (this.dischargeLetter !== undefined && this.dischargeLetter.length > 0) {
                let blob = this.dischargeLetter[0];
                let newFile = new File(
                  [blob],
                  "DischargeLetter_" +
                    patient_id +
                    "_" +
                    Math.floor(new Date().getTime() / 1000) +
                    ".pdf",
                  { type: blob.type },
                );
                let formData = new FormData();
                formData.append("file", newFile);

                this.$axios
                  .post("/upload/", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then(
                    (response) => {
                      if ("error" in response.data) {
                        console.log(response.data);
                        return;
                      } else {
                        console.log("File Upload successfull");
                        console.log(response.data.success.path);
                        dischargeLetterPath = response.data.success.path;
                      }
                      // console.log("TreatmentDocument created")
                      // this.$emit('close-dialog');
                    },
                    (error) => {
                      console.log(error);
                    },
                  )
                  .then(() => {
                    this.reasonsOfCall.hospitalization.discharge_letter_pdf =
                      dischargeLetterPath;

                    this.$axios
                      .post("/treatment_documentation/", json_payload, {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      })
                      .then(
                        (response) => {
                          console.log(response);
                          if ("success" in response.data) {
                            console.log(response.data);
                            this.$emit("close-dialog");
                          } else {
                            console.log(response.data);
                          }
                        },
                        () => {
                          console.log("TreatmentDocument not created");
                        },
                      );
                  });
              } else {
                this.$axios
                  .post("/treatment_documentation/", json_payload, {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                  .then(
                    (response) => {
                      console.log(response);
                      if ("success" in response.data) {
                        console.log(response.data);
                        this.$emit("close-dialog");
                      } else {
                        console.log(response.data);
                      }
                    },
                    () => {
                      console.log("TreatmentDocument not created");
                    },
                  );
              }
            }
          }
        },
        () => {},
      );
    },

    fetchDischargePDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.dischargeLetterPDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.dischargeFileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },

    fetchPDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.fileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },

    checkSelections: function () {
      if (this.reasonsOfCall.worsening.selected.includes("anderes")) {
        this.reasonsOfCall.worsening.changeBool = true;
      } else {
        this.reasonsOfCall.worsening.changeBool = false;
      }
    },

    clearInput: function() {
      this.dateOfProcedure = ""
    }
  },

  watch: {
    dischargeLetterPDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchDischargePDFFile();
        }
        console.log(this.dischargeFileBlob);
      },
    },
    filePDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchPDFFile();
        }
        console.log(this.fileBlob);
      },
    },
  },

  mounted() {},
};
</script>
