<script setup>
// import DataRating from './DataRating.vue';
</script>

<template>
  <v-container>
    <v-progress-circular
      v-if="loading"
      class="align-center justify-center"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-expansion-panels v-if="!loading" v-model="panel" variant="accordion">
      <v-col>
        <v-row v-for="(data, key, index) in handmove_data_grouped" :key="index">
          <v-expansion-panel @click="panelStateChanged(key, index)">
            <v-expansion-panel-title>
              Faustöffnung vom
              {{ helpers.formatDateStringToGermanDateTime(key) }} Uhr
            </v-expansion-panel-title>
            <!--
                        <v-expansion-panel-text>
                            <v-row>
                                <v-col cols="6">
                                    Linke Hand
                                    <div :ref="'plot-left' + index"><canvas style="pointer-events: none;"></canvas></div>
                                    // show text if data is missing
                                </v-col>
                                <v-col cols="6">
                                    Rechte Hand
                                    <div :ref="'plot-right' + index"><canvas style="pointer-events: none;"></canvas></div>
                                    // show text if data is missing
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <DataRating :patient_id="patient_id" :test_type="'handmove'"
                                        :fhir_id="'handmove' + key" />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-text>
                    -->
            <v-expansion-panel-text>
              <p>Diese Daten sind in der Datenbank vorhanden, die Visualisierung ist jedoch noch nicht umgesetzt.</p>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-row>
      </v-col>
    </v-expansion-panels>
  </v-container>
</template>

<style>
.plot {
  max-width: 50%;
}
</style>

<script>
import Chart from "chart.js/auto";

export default {
  name: "HandMovement",

  data: () => ({
    handmove_data_grouped: [],
    handmove_data: [],
    patient_id: "",
    panelOpen: false,
    panel: [],
    graphs: [],
    loading: true,
    left_exists: true,
    right_exists: true,
  }),

  methods: {
    getTappingData: function () {
      this.loading = true;
      this.$axios
        .get("/get_data/", {
          params: {
            param: "65698-3",
            patient_id: this.patient_id,
          },
        })
        .then(
          (response) => {
            console.log(response.data);
            this.handmove_data = JSON.parse(
              response.data.replace(/NaN/g, "null"),
            );
          },
          (error) => {
            console.log(error);
          },
        )
        .then(
          () => {
            this.$axios
              .get("/get_data/", {
                params: {
                  param: "65697-5",
                  patient_id: this.patient_id,
                },
              })
              .then(
                (response) => {
                  console.log(response.data);
                  this.handmove_data = this.handmove_data.concat(
                    JSON.parse(response.data.replace(/NaN/g, "null")),
                  );
                },
                (error) => {
                  console.log(error);
                },
              )
              .then(() => {
                console.log(this.handmove_data);
                const groupedData = this.handmove_data.reduce((acc, item) => {
                  // Extract the date portion without the time
                  const datetime = item.datetime;
                  const dateinmillis = new Date(datetime).getTime();

                  const existing_datetime = Object.keys(acc);

                  let matched_group = "";
                  let matched_difference = 1000000;

                  existing_datetime.forEach((element) => {
                    let difference_in_time =
                      new Date(element).getTime() - dateinmillis;
                    difference_in_time = Math.abs(difference_in_time / 1000);

                    console.log(difference_in_time);

                    if (
                      difference_in_time < 300 &&
                      difference_in_time < matched_difference
                    ) {
                      matched_group = element;
                      matched_difference = difference_in_time;
                    }
                  });

                  if (matched_group != "") {
                    // Check if the date exists in the accumulator
                    if (!acc[matched_group]) {
                      acc[matched_group] = [];
                    }

                    // Push the item to the corresponding date
                    acc[matched_group].push(item);
                  } else {
                    if (!acc[datetime]) {
                      acc[datetime] = [];
                    }

                    // Push the item to the corresponding date
                    acc[datetime].push(item);
                  }

                  return acc;
                }, {});
                this.handmove_data_grouped = groupedData;

                let order = Object.keys(this.handmove_data_grouped).sort(
                  function (a, b) {
                    return new Date(b) - new Date(a);
                  },
                );

                let temp = {};
                order.forEach((key) => {
                  temp[key] = this.handmove_data_grouped[key];
                });
                this.handmove_data_grouped = temp;

                console.log(order);
                // this.loading = false
                // waits three seconds before setting loading to true
                setTimeout(this.ExpPanelDelay(), 3000);
                console.log(this.handmove_data_grouped);
              });
          },
          () => {},
        );
    },

    updatePlot: function (key, index) {
      if (!this.helpers.isEmpty(this.tapping_data_grouped)) {
        console.log(this.tapping_data_grouped[key]);
        console.log(this.$refs);

        const options = {
          scales: {
            x: {
              title: {
                display: true,
                text: "Zeit in Sek.", // X-axis label
              },
            },
            y: {
              title: {
                display: true,
                text: "Distanz in mm", // Y-axis label
              },
            },
          },
          legend: {
            display: false, // Hide the legend
            labels: {
              boxWidth: 0, // Set the width of the colored box to 0 to hide it
            },
          },
          responsive: true,
          maintainAspectRatio: false, // Adjust as needed
          events: [],
          onClick: null,
        };

        if (
          "plot-left" + index in this.$refs &&
          this.handmove_data_grouped[key].find((el) => el.code === '65698-3') !== undefined
        ) {
          let data_left = this.handmove_data_grouped[key].find((el) => el.code === '65698-3')
          let time_axis_left = data_left["time_axis"].map(function (el) {
            return Number(el.toFixed(1));
          });
          let value_axis_left = data_left["value_axis"];

          var ctx_left = this.$refs["plot-left" + index][0]
            .querySelector("canvas")
            .getContext("2d");

          console.log(
            this.$refs["plot-left" + index][0].querySelector("canvas"),
          );

          if (this.graphs[0] != null) {
            this.graphs[0].destroy(); // Destroy the left chart if it exists
          }

          this.graphs[0] = new Chart(ctx_left, {
            type: "line",
            data: {
              labels: time_axis_left,
              datasets: [
                {
                  data: value_axis_left,
                  label: "",
                  borderColor: "blue", // Line color
                  borderWidth: 2,
                  pointRadius: 0,
                },
              ],
            },
            options: options,
          });

          this.$refs["plot-left" + index][0].querySelector("canvas").remove;
          // set left_exists back to true in case it was previously set false
          if (this.left_exists === false) {
            this.left_exists = true;
          }
        } else {
          // show some text if left chart has no data
          this.left_exists = false;
        }

        if (
          "plot-right" + index in this.$refs &&
          this.handmove_data_grouped[key].find((el) => el.code === '65697-5') !== undefined
        ) {
          let data_right = this.handmove_data_grouped[key].find((el) => el.code === '65697-5')
          let time_axis_right = data_right["time_axis"].map(function (el) {
            return Number(el.toFixed(1));
          });
          let value_axis_right = data_right["value_axis"];

          var ctx_right = this.$refs["plot-right" + index][0]
            .querySelector("canvas")
            .getContext("2d");

          console.log(
            this.$refs["plot-right" + index][0].querySelector("canvas"),
          );

          if (this.graphs[1] != null) {
            this.graphs[1].destroy(); // Destroy the right chart if it exists
          }

          this.graphs[1] = new Chart(ctx_right, {
            type: "line",
            data: {
              labels: time_axis_right,
              datasets: [
                {
                  data: value_axis_right,
                  label: "",
                  borderColor: "red", // Line color
                  borderWidth: 2,
                  pointRadius: 0,
                },
              ],
            },
            options: options,
          });

          this.$refs["plot-right" + index][0].querySelector("canvas").remove;
          // set right_exists back to true in case it was previously set false
          if (this.right_exists === false) {
            this.right_exists = true;
          }
        } else {
          // show some text if right chart has no data
          this.right_exists = false;
        }
      }
    },

    panelStateChanged(key, index) {
      // Update the state when the panel is opened or closed
      console.log("this.panelStateChanged");
      this.$nextTick(() => {
        this.updatePlot(key, index);
        return new Promise((resolve) => setTimeout(resolve, 1500));
      });
    },

    switchedTo: function () {
      this.fetchPatientData();
    },

    ExpPanelDelay() {
      this.loading = false;
    },
  },

  watch: {
    panelOpen(newValue) {
      // Now you can work with the button when the panel is open
      if (newValue) {
        console.log(this.$refs);
      }
    },
  },

  computed: {},

  mounted() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
    this.getTappingData();
  },

  expose: ["switchedTo"],
};
</script>
