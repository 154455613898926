<script setup>
import UPDRSDetailedView from "./UPDRSDetailedDisplay.vue";
</script>

<template>
  <v-sheet
    border="md"
    rounded="lg"
    width="100%"
    class="my-sheet pa-4 text-left mx-auto mb-2 mr-2"
  >
    <h2 class="text-h5 mb-6">MDS-UPDRS</h2>
    <v-row class="mt-n2">
      <v-col>
        <!-- set detailedView back to true to show the detailed display again -->
        <div v-if="hasData" ref="updrs_plot"><canvas @click="detailedView = true"></canvas></div>
        <div v-else>Daten nicht vorhanden</div>
      </v-col>
    </v-row>
    <v-row v-if="!helpers.isEmpty(updrs_data)" class="mt-n2">
      <v-col>
        <v-expansion-panels variant="accordion">
          <v-expansion-panel>
            <v-expansion-panel-title>
              MDS-UPDRS vom
              {{ helpers.formatDateStringToGermanDate(updrs_data[0].datetime) }}
              Uhr - Score: {{ updrs_data[0].score.Gesamtscore }}
            </v-expansion-panel-title>

            <v-expansion-panel-text v-html="updrs_data[0].display">
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-sheet>

  <v-dialog v-model="detailedView" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">MDS-UPDRS Teilvisualisierung</span>
      </v-card-title>
      <v-card-text>
          <UPDRSDetailedView></UPDRSDetailedView>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="detailedView = false"
            >
              Schließen
            </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "UPDRSDisplay",

  data: () => ({
    updrs_data: {},
    graph: null,
    patient_id: "",
    detailedView: false,
    hasData: false,
  }),

  methods: {
    getUPDRSData: function () {
      this.$axios
        .get("/get_updrs/" + this.patient_id)
        .then(
          (response) => {
            console.log(response.data);
            this.updrs_data = response.data;
            if(!this.helpers.isEmpty(this.updrs_data)) {
              this.hasData = true
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .then(
          () => {
            if (this.hasData) {
              console.log(this.updrs_data);
              this.updatePlot();
            }
          },
          () => {}
        );
    },

    updatePlot: function () {
      console.log(!this.helpers.isEmpty(this.updrs_data));
      if (!this.helpers.isEmpty(this.updrs_data)) {
        console.log("updateplot");
        var data = {
          labels: Object.keys(this.updrs_data[0]["score"]),
          datasets: [
            {
              label:
                "UPDRS vom " +
                this.helpers.formatDateStringToGermanDate(
                  this.updrs_data[0]["datetime"]
                ),
              data: Object.values(this.updrs_data[0]["score"]), // Replace with your data values
              backgroundColor: "rgba(75, 192, 192, 0.2)", // Fill color
              borderColor: "rgba(75, 192, 192, 1)", // Border color
              borderWidth: 2, // Border width
            },
          ],
        };

        // Configuration options for the Radar Chart
        var options = {
          responsive: true,
          maintainAspectRatio: false,
          scale: {
            angleLines: {
              display: true,
            },
            ticks: {
              beginAtZero: true,
              max: 10, // Adjust the maximum scale value as needed
            },
          },
        };

        console.log(this.$refs);

        var ctx = this.$refs["updrs_plot"]
          .querySelector("canvas")
          .getContext("2d");

        this.graph = new Chart(ctx, {
          type: "radar",
          data: data,
          options: options,
        });
      }
    },

    switchedTo: function () {
      this.getUPDRSData();
    },
  },

  mounted() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
    this.getUPDRSData();
  },

  expose: ["switchedTo"],
};
</script>
