<style>
.alert_system {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <v-card>
    <v-row class="ma-0 pa-0"
      ><v-col
        ><h3 class="text-left">
          Alle Patienten
          <span v-if="activePatients === 'true'"> (Interventionsgruppe)</span
          ><span class="text-left" v-if="activePatients === 'false'">
            (Kontrollgruppe)</span
          >
          <span class="text-left" v-if="activePatients === 'deleted'">
            (deaktiviert)</span
          >
        </h3></v-col
      ></v-row
    >
    <v-row
      class="ma-0 pa-0"
      v-if="helpers.checkForRole(keycloak.token, 'coordinator')"
    >
      <v-col class="text-left">
        <v-btn
          :to="{ name: 'create-patient' }"
          class="btn btn-primary"
          prepend-icon="mdi-plus"
          >Patienten anlegen</v-btn
        >
      </v-col>
    </v-row>
    <v-row lcass="ma-0 pa-0">
      <v-col
        ><v-text-field
          v-model="search"
          label="Suche"
          single-line
          hide-details
        ></v-text-field></v-col
    ></v-row>

    <v-data-table
      :items-per-page="-1"
      :headers="computedHeaders"
      :search="search"
      :items="Object.values(patientsDisplay)"
      @update:sort-by="handleSorting"
      @update:sort-desc="handleSorting"
      :loading="loading.patients"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr
          @click="
            $router.push({
              name: 'patient-view',
              params: { id: item.patient_id, view: 'overview_view' },
            })
          "
        >
          <td class="text-left">{{ item.patient_id }}</td>
          <td class="text-left">
            {{ item.first_name + " " + item.last_name }}
          </td>
          <td class="text-left">{{ item.birth_date }}</td>
          <td class="text-left">{{ item.study_center }}</td>
          <td class="text-left">{{ formatDateTime(item.created_at) }}</td>
          <td class="text-left">{{ chatMessage(item.latest_message) }}</td>
          <td class="align-center justify-end" v-if="activePatients === 'true'">
            <AlertSystem
              class="alert_system"
              :patient_id="item.patient_id"
              :display-all="true"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import AlertSystem from "../alertsystem/AlertSystem.vue";
import { updateIds } from "@/composable/id-list.js";
import helpers from "../helpers/helpers";

export default {
  name: "PatientList",

  props: ["active"],

  components: {
    AlertSystem,
  },

  setup() {
    const statusOrder = ["green", "yellow", "red"];
    return { statusOrder, updateIds };
  },

  data: () => ({
    headers: [
      { title: "Patienten-ID", key: "patient_id", align: "left" },
      { title: "Vor- und Nachname", key: "full_name", align: "left" },
      { title: "Geburtsdatum", key: "birth_date", align: "left" },
      { title: "Studienzentrum", key: "study_center", align: "left" },
      { title: "Einschlussdatum", key: "created_at", aign: "left" },
      {
        title: "Aktuellste Chatnachricht",
        key: "latest_message.date",
        aign: "left",
      },
      {
        title: "Ampelstatus",
        key: "latest_status",
        align: "center",
        sortable: false,
      },
    ],
    patients: [],
    loading: {
      patients: true,
    },
    search: "",
  }),

  methods: {
    fetchPatientData: function () {
      let patient_status = this.active.includes("true") ? 2 : 3;
      // check if the deleted patient list was triggered, if yes the flag gets 4. If not, it will remain as the above value
      patient_status = this.active.includes("deleted") ? 4 : patient_status;
      console.log(this.active);
      this.$axios
        .get("/get_patients/" + patient_status)
        .then(
          (response) => {
            console.log(response.data);
            this.patients = response.data;
            console.log(this.patients[0]);
          },
          () => {}
        )
        .then(
          () => {
            console.log("finished");
            this.loading.patients = false;
          },
          () => {}
        );
    },

    handleSorting(sortBy) {
      let idList = [];
      if (!helpers.isEmpty(sortBy)) {
        console.log(sortBy);
        let param = sortBy[0].key;
        let order = sortBy[0].order;
        Object.values(this.patients)
          .sort((a, b) => {
            switch (param) {
              case "patient_id":
              case "full_name":
              case "study_center":
                return order == "asc"
                  ? a[param].localeCompare(b[param])
                  : b[param].localeCompare(a[param]);
              case "birth_date":
              case "created_at":
              case "latest_message.date":
                return order == "asc"
                  ? new Date(a[param]) - new Date(b[param])
                  : new Date(b[param]) - new Date(a[param]);
              case "latest_status":
                return order == "asc"
                  ? this.statusOrder.indexOf(a[param]) -
                      this.statusOrder.indexOf(b[param])
                  : this.statusOrder.indexOf(b[param]) -
                      this.statusOrder.indexOf(a[param]);
            }
          })
          .forEach((element) => {
            idList.push(element.patient_id);
          });

        console.log(idList);

        updateIds(idList);
      } else {
        this.fetchPatientData();
      }
    },

    sortStatus(items) {
      console.log(items);
      return items.sort((a, b) => {
        return (
          this.statusOrder.indexOf(a.status) -
          this.statusOrder.indexOf(b.status)
        );
      });
    },

    formatDateTime: function (dateStr) {
      return new Date(dateStr).toLocaleDateString("en-CA");
    },

    chatMessage: function (messageObj) {
      let today = 1000 * 60 * 60 * 24 * 7;
      let lastWeek = new Date().getTime() - today;
      if (new Date(messageObj.date).getTime() < lastWeek || !messageObj.date) {
        return "Keine neuen Chatnachrichten (seit mind. 7 Tagen)";
      } else {
        return "'" + messageObj.comment + "' vom " + this.formatDateTime(messageObj.date) + ' gesendet von ' + messageObj.user_id;
      }
    },

    userNameInfo: function () {
      let infos = helpers.getUserInfoFromToken(this.keycloak.token)
      return infos.first_name + " " + infos.last_name
    },
  },

  computed: {
    computedHeaders() {
      const baseHeaders = [
        { title: "Patienten-ID", key: "patient_id", align: "left" },
        { title: "Vor- und Nachname", key: "full_name", align: "left" },
        { title: "Geburtsdatum", key: "birth_date", align: "left" },
        { title: "Studienzentrum", key: "study_center", align: "left" },
        { title: "Einschlussdatum", key: "created_at", align: "left" },
        {
          title: "Aktuellste Chatnachricht",
          key: "latest_message.date",
          align: "left",
        },
      ];

      if (this.activePatients === 'true') {
        baseHeaders.push({
          title: "Ampelstatus",
          key: "latest_status",
          align: "center",
          sortable: true,
          sort: (a, b) => {
            return this.statusOrder.indexOf(a) - this.statusOrder.indexOf(b);
          },
        });
      }

      return baseHeaders;
    },

    patientsDisplay: function () {
      let list = [];

      Object.values(this.patients).forEach((element) => {
        element.full_name = element.first_name + " " + element.last_name;
        console.log(element);
        list.push(element.patient_id);
      });

      this.updateIds(list);

      return this.patients;
    },
  },

  mounted() {
    this.fetchPatientData();
  },

  watch: {
    "$route.params": {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        // use str instead of booleans to assign 3 values
        if (newVal.active.includes("true")) {
          this.activePatients = 'true';
        } else if(newVal.active.includes("deleted")) {
          this.activePatients = 'deleted';
        }  else {
          this.activePatients = 'false';
        }
        this.loading.patients = true;
        this.fetchPatientData();
      },
    },
  },

  expose: ["fetchPatientData"],
};
</script>
