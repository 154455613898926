<template>
    <v-container>
    <v-form ref="form" :disabled="submitted" fast-fail @submit.prevent="addDocumentation">

        <v-select v-model="selected_institution" label="Institution" :items=labels_institution 
         item-title="label" item-value="val" :rules="notEmptyRule"></v-select>
        <v-select v-model="selected_data" label="Benötigte Daten" :items=labels_data item-title="label" 
         item-value="val" :rules="notEmptyRule" @update:model-value="checkSelections()"></v-select>

        <v-text-field
          v-if="is7"
          v-model="input_patient_id"
          label="Patienten ID"
        ></v-text-field>

        <v-btn type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
    </v-container>
</template>

<script>

export default {
    name: "DataExport",

    data: function () {
    return {
        notEmptyRule: [
        (value) => {
            if (value) return true;

            return "Dies ist ein Pflichtfeld";
        },
        ],
        labels_institution: [{ label: 'Institut für Versorgungsforschung Marburg', val: 1} , { label: 'LiKe Healthcare Research GmbH', val: 2 }, { label: 'Universität zu Lübeck', val: 3 }, 
            { label: 'Fraunhofer Institut (FIT)', val: 4 }, { label: 'Klinik für Neurologie Philipps Universität Marburg', val: 5} ],
        selected_institution: '',
        labels_data: [{ label: 'Patientenliste', val: 1 }, { label: 'Versorgungsdokumentationen (aller Patienten)', val: 2 } , { label: 'Motorik-Tests (aller Patienten)', val: 3 }, 
            { label: 'Antworten zu Fragebögen (aus Q-Form; aller Patienten)', val: 4 }, { label: 'WHO-5 Antworten (aus mobiler App; aller Patienten)', val: 5 },
            { label: 'Logdaten des Selbstmanagement-Tools (aller Patienten)', val: 6 } ,
            { label: 'Versorgungsdokumentationen für Abrechnung mit Techniker Krankenkasse (aller Patienten)', val: 7 },
            { label: 'Medikationspläne (aller Patienten)', val: 8 },
            { label: 'Symptom-Meldungen (aus mobile App; aller Patienten)', val: 9}
        ],
        selected_data: '',
        is7: false,
        input_patient_id: '',
        submitted: false
    };
    },

    methods: {
        addDocumentation: function () {
            this.$refs.form.validate().then(
            (status) => {
                if (status.valid === true) {

                    let params = new URLSearchParams()
                    params.append('institution', this.selected_institution)
                    params.append('needed_data', this.selected_data)
                    params.append('needed_patient', this.input_patient_id)

                    var request = { params: params }
                    this.$axios
                    .get("/get_exports/", request
                    )
                    .then(
                        (response) => {
                        console.log(response);
                        this.$router.push({
                            name: "patient-list",
                            params: { active: true },
                        });
                        },
                        () => {
                        console.log("DataExport not created");
                        },
                    );
                }
            },
            () => {},
            );
        },

        checkSelections: function() {
            if (this.selected_data === 7) {
                this.is7 = true
            } else {
                this.is7 = false
            }
        }
    },
    
    mounted() {
    console.log(this.disabled);
    },
};
</script>
