<template>
  <v-container>
    <v-form ref="form" :disabled="submitted" fast-fail @submit.prevent="addDocumentation">
      <v-text-field
        :readonly="disabled"
        v-model="dateOfCall"
        :max="new Date().toISOString().slice(0, -14)"
        type="date"
        :rules="notEmptyRule"
        label="Tag des Telefonats"
        @keydown="clearInput()"
      ></v-text-field>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRule"
        label="Dauer des Konsil (HH:MM)"
      ></v-text-field>

      <v-file-input
        v-show="!disabled"
        v-model="patientBriefingFile"
        :rules="fileRules"
        accept=".pdf"
        label="Eingangsgespräch via Telefon"
      ></v-file-input>

      <v-expansion-panels v-if="filePDF !== 'Not provided'">
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-col class="text-left">Eingangsgespräch via Telefon</v-col>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <vue-pdf-embed v-show="filePDF" :source="fileBlob" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-label class="text-left">Grund des Telefonats</v-label>
      <v-radio-group :readonly="disabled" v-model="reasonOfCall">
        <v-radio
          label="Einschluss in die Studie"
          value="study_inclusion"
        ></v-radio>
      </v-radio-group>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-select
        :readonly="disabled"
        v-model="selected.values"
        :items="selects"
        label="Optional auszuwählen"
        multiple
      ></v-select>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import VueJWTDecode from "vue-jwt-decode";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "PatientBriefing",

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  components: {
    VuePdfEmbed,
  },

  data: function () {
    return {
      title: "Telefonisches Eingangsgespräch Telenurse - Patient",
      type: "patient_briefing",
      dateOfCall: this.document_data ? this.document_data.treatment_date : "",
      reasonOfCall: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "reasonOfCall",
          ).value
        : "",
      billableProcedures: ["A04"],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration",
          ).value
        : "",
      filePDF: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "patientBriefingFile",
          ).value
        : "Not provided",
      fileBlob: undefined,
      patientBriefingFile: undefined,
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 5 * 1024 * 1024 ||
            "Datei darf nicht größer als 5 MB sein!"
          );
        },
        (value) => {
          if (value) return true;

          return "Sie müssen ein Eingangsgespräch hochladen";
        },
      ],
      durationRule: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      disabled: this.disabled_field,
      selects: [
        "Pflegeassessment angezeigt",
        "Arztkonsultation angezeigt",
        "Selbstmanagementempfehlung ausgegeben (Reminder: Nr. des TipSheet im Freitext dokumentieren!)",
      ],
      selected: this.document_data ? this.document_data.content.find((item) => item.property === "selected").value : { values: [] },
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      submitted: false
    };
  },

  methods: {
    addDocumentation: function () {
      this.$refs.form.validate().then(
        (status) => {
          if (status.valid === true) {
            this.submitted = true
            const token = this.keycloak.token;
            const decoded_token = VueJWTDecode.decode(token);
            let user_name = decoded_token.preferred_username;

            let patient_id = this.$route.params.id.split("&", 1)[0];
            console.log(user_name);
            console.log(patient_id);

            let patientBriefingFilePath = undefined;

            if (this.patientBriefingFile !== undefined && this.patientBriefingFile.length > 0) {
              let blob = this.patientBriefingFile[0];
              let newFile = new File(
                [blob],
                "PatientBriefing_" +
                  patient_id +
                  "_" +
                  Math.floor(new Date().getTime() / 1000) +
                  ".pdf",
                { type: blob.type },
              );
              let formData = new FormData();
              formData.append("file", newFile);

              this.$axios
                .post("/upload/", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(
                  (response) => {
                    if ("error" in response.data) {
                      console.log(response.data);
                      return;
                    } else {
                      console.log("File Upload successfull");
                      console.log(response.data.success.path);
                      patientBriefingFilePath = response.data.success.path;
                    }
                    // console.log("TreatmentDocument created")
                    // this.$emit('close-dialog');
                  },
                  (error) => {
                    console.log(error);
                  },
                )
                .then(() => {
                  let json_payload = {
                    user_id: user_name,
                    patient_id: patient_id,
                    treatment_date: this.dateOfCall,
                    documentation_date: new Date().toISOString(),
                    treamtent_type: this.type,
                    billable_procedures: this.billableProcedures,
                    title: this.title,
                    content: [
                      { property: "reasonOfCall", value: this.reasonOfCall },
                      { property: "duration", value: this.duration },
                      {
                        property: "patientBriefingFile",
                        value: patientBriefingFilePath,
                      },
                      { property: "further", value: this.further },
                      { property: "selected", value: this.selected },
                    ],
                  };

                  console.log(json_payload);

                  this.$axios
                    .post("/treatment_documentation/", json_payload, {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then(
                      (response) => {
                        console.log(response);
                        if ("success" in response.data) {
                          console.log(response.data);
                          this.$emit("close-dialog");
                        } else {
                          console.log(response.data);
                        }
                      },
                      () => {
                        console.log("TreatmentDocument not created");
                      },
                    );
                });
            } else {
              let json_payload = {
                user_id: user_name,
                patient_id: patient_id,
                treatment_date: this.dateOfCall,
                documentation_date: new Date().toISOString(),
                treamtent_type: this.type,
                billable_procedures: this.billableProcedures,
                title: this.title,
                content: [
                  { property: "reasonOfCall", value: this.reasonOfCall },
                  { property: "duration", value: this.duration },
                  { property: "patientBriefingFile", value: "Not provided" },
                  { property: "further", value: this.further },
                  { property: "selected", value: this.selected },
                ],
              };

              this.$axios
                .post("/treatment_documentation/", json_payload, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then(
                  (response) => {
                    console.log(response.data);
                    this.$emit("close-dialog");
                  },
                  () => {
                    console.log("Patient not created");
                  },
                );
            }
          }
        },
        () => {},
      );
    },
    fetchPDFFile: function () {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
        headers: {
          Authorization: "Bearer " + this.keycloak.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var that = this; //the magic happens
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            that.fileBlob = base64data;
          };
        })
        .catch((error) => console.error("Error:", error));
    },

    clearInput: function() {
      this.dateOfCall = ""
    }
  },

  watch: {
    filePDF: {
      immediate: true,
      handler(newVal) {
        // React to route parameter change
        // For example, fetch new data based on the new parameter
        console.log(newVal);
        if (newVal != undefined) {
          this.fetchPDFFile();
        }
        console.log(this.fileBlob);
      },
    },
  },

  mounted() {
    console.log(this.disabled);
  },
};
</script>
