<script setup>
import PatientPostCreate from "../patient/PatientPostCreate.vue";
</script>

<template>
  <div v-if="!postCreate">
    <h1>Patient erstellen</h1>
    <v-form ref="form" fast-fail @submit.prevent="addPatient">
      <v-label class="align-left">Interventionsgruppe?*</v-label>
      <v-radio-group :disabled="disabled" v-model="active">
        <v-radio label="Interventionsgruppe" v-bind:value="true"></v-radio>
        <v-radio label="Kontrollgruppe" v-bind:value="false"></v-radio>
      </v-radio-group>

      <v-text-field
        :disabled="disabled"
        v-model="firstName"
        label="Vorname*"
        :rules="firstNameRules"
      ></v-text-field>
      <v-text-field
        :disabled="disabled"
        v-model="lastName"
        label="Nachname*"
        :rules="lastNameRules"
      ></v-text-field>
      <v-text-field
        :disabled="disabled"
        v-model="birthDate"
        label="Geburtsdatum*"
        :rules="notEmptyRules"
        type="date"
      ></v-text-field>

      <v-label class="align-left">Geschlecht*</v-label>
      <v-radio-group
        :disabled="disabled"
        v-model="gender"
        :rules="notEmptyRules"
      >
        <v-radio label="männlich" value="male"></v-radio>
        <v-radio label="weiblich" value="female"></v-radio>
        <v-radio label="divers" value="other"></v-radio>
      </v-radio-group>

      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="phone"
            label="Telefonnummer"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="email"
            label="E-Mail Adresse"
            :rules="eMailRules"
          ></v-text-field
        ></v-col>
      </v-row>

      <v-text-field
        :disabled="disabled"
        v-model="street"
        label="Straße und Hausnummer"
      ></v-text-field>
      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="zipCode"
            label="Postleitzahl"
            :rules="zipCodeRules"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="city"
            label="Ort"
          ></v-text-field
        ></v-col>
      </v-row>

      <v-label class="align-left">Kostenträger</v-label>
      <v-select
        v-model="insurer"
        :items="[
          'AOK',
          'Barmer',
          'DAK',
          'KKH',
          'Techniker',
          'IKK Classic',
          'Knappschaft',
          'Sonstige',
        ]"
      ></v-select>

      <v-text-field
        :disabled="disabled"
        v-if="insurer == 'AOK' || insurer == 'Sonstige'"
        v-model="insurerTxt"
        :label="insurer + ' Name'"
      ></v-text-field>

      <v-label class="align-left text-h6">Behandelnder Hausarzt</v-label>
      <v-text-field
        :disabled="disabled"
        v-model="familyDoctor.name"
        label="Praxisname"
      ></v-text-field>

      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="familyDoctor.phone"
            label="Telefonnummer"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="familyDoctor.email"
            label="E-Mail Adresse"
            :rules="eMailRules"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-text-field
        :disabled="disabled"
        v-model="familyDoctor.street"
        label="Straße und Hausnummer"
      ></v-text-field>
      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="familyDoctor.zip_code"
            label="Postleitzahl"
            :rules="zipCodeRules"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="familyDoctor.city"
            label="Ort"
          ></v-text-field
        ></v-col>
      </v-row>

      <v-label class="align-left text-h6">Behandelnder Neurologe</v-label>
      <v-text-field
        :disabled="disabled"
        v-model="neurologist.name"
        label="Praxisname"
      ></v-text-field>

      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="neurologist.phone"
            label="Telefonnummer"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="neurologist.email"
            label="E-Mail Adresse"
            :rules="eMailRules"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-text-field
        :disabled="disabled"
        v-model="neurologist.street"
        label="Straße und Hausnummer"
      ></v-text-field>
      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="neurologist.zip_code"
            label="Postleitzahl"
            :rules="zipCodeRules"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="neurologist.city"
            label="Ort"
          ></v-text-field
        ></v-col>
      </v-row>

      <v-label class="align-left text-h6"
        >Ambulanter Pflegedienst vorhanden?</v-label
      >
      <v-radio-group
        :disabled="disabled"
        v-model="ambCare"
        :rules="genderRules"
      >
        <v-radio label="Nein" value="false"></v-radio>
        <v-radio label="Ja" value="true"></v-radio>
      </v-radio-group>

      <div id="ambCoreObject" v-if="ambCare == 'true' && !disabled">
        <v-label class="align-left">Ambulanter Pflegedienst</v-label>
        <v-text-field
          :disabled="disabled"
          v-model="ambCareObject.name"
          label="Name des Pflegedienst"
        ></v-text-field>
        <v-row>
          <v-col
            ><v-text-field
              :disabled="disabled"
              v-model="ambCareObject.phone"
              label="Telefonnummer"
            ></v-text-field
          ></v-col>
          <v-col
            ><v-text-field
              :disabled="disabled"
              v-model="ambCareObject.email"
              label="E-Mail Adresse"
              :rules="eMailRules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-text-field
          :disabled="disabled"
          v-model="ambCareObject.street"
          label="Straße und Hausnummer"
        ></v-text-field>
        <v-row>
          <v-col
            ><v-text-field
              :disabled="disabled"
              v-model="ambCareObject.zip_code"
              label="Postleitzahl"
              :rules="zipCodeRules"
            ></v-text-field
          ></v-col>
          <v-col
            ><v-text-field
              :disabled="disabled"
              v-model="ambCareObject.city"
              label="Ort"
            ></v-text-field
          ></v-col>
        </v-row>
      </div>
      <!--
            <v-label class="align-left text-h6">Persönliche Informationen</v-label>
            <v-row>
                <v-col><v-label class="align-left">Familienstand</v-label>
                    <v-radio-group :disabled="disabled" v-model="martialStatus">
                        <v-radio label="verheiratet/Lebenspartnerschaft" value="married"></v-radio>
                        <v-radio label="unverheiratet/ledig" value="single"></v-radio>
                        <v-radio label="geschieden" value="divorced"></v-radio>
                        <v-radio label="verwitwet" value="widowed"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col><v-label class="align-left">Wohnsituation</v-label>
                    <v-radio-group :disabled="disabled" v-model="situLiving">
                        <v-radio label="Privater Haushalt" value="private_household"></v-radio>
                        <v-radio label="Seniorenwohnung" value="elderly_household"></v-radio>
                        <v-radio label="Altersheim" value="elderly_home"></v-radio>
                        <v-radio label="Pflegeheim" value="care_home"></v-radio>
                        <v-radio label="Sonstige" value="misc"></v-radio>
                    </v-radio-group>
                    <v-text-field v-if="situLiving === 'misc'" :disabled="disabled" v-model="situLivingTxt"
                        label="Sonstige Wohnsituation"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col><v-label class="align-left">Muttersprache</v-label>
                    <v-radio-group :disabled="disabled" v-model="motherLang">
                        <v-radio label="Deutsch" value="german"></v-radio>
                        <v-radio label="Sonstige" value="misc"></v-radio>
                    </v-radio-group>
                    <v-text-field v-if="motherLang === 'misc'" :disabled="disabled" v-model="motherLangTxt"
                        label="Sonstige Muttersprache"></v-text-field>
                </v-col>
                <v-col><v-label class="align-left">Aktuell noch erwerbstätig?</v-label>
                    <v-radio-group :disabled="disabled" v-model="jobActive">
                        <v-radio label="Ja" value="true"></v-radio>
                        <v-radio label="Nein" value="false"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            -->
      <v-label class="align-left text-h6"
        >Krankheitsspezifische Informationen</v-label
      >
      <v-text-field
        :disabled="disabled"
        v-model="diagnosisTime"
        type="month"
        :max="new Date().toISOString().slice(0, -17)"
        label="Zeitpunkt der Parkinson Diagnose"
      ></v-text-field>

      <v-checkbox
        :disabled="disabled"
        v-model="symptomFall.present"
        label="Wiederkehrende Stürze vorhanden?"
      ></v-checkbox>

      <v-text-field
        :disabled="disabled"
        v-if="symptomFall.present"
        v-model="symptomFall.duration"
        label="Stürze präsent seit? (Monate)"
      ></v-text-field>

      <v-text-field
        :disabled="disabled"
        v-if="symptomFall.present"
        v-model="symptomFall.last_event"
        type="month"
        :max="new Date().toISOString().slice(0, -17)"
        label="Zeitpunkt letzter Sturz"
      ></v-text-field>

      <v-text-field
        :disabled="disabled"
        v-if="symptomFall.present"
        v-model="symptomFall.frequency"
        label="Sturzhäufig (pro Woche)"
      ></v-text-field>

      <v-select
        v-model="hyStage"
        :items="hyStageDescription"
        item-title="description"
        item-value="value"
        label="Hoehn und Yahr Stadium"
      ></v-select>

      <v-btn :disabled="disabled" type="submit" block class="mt-2"
        >Submit</v-btn
      >
    </v-form>
  </div>
  <div v-if="postCreate">
    <PatientPostCreate :patient="patient" :qr_code="qr_code" />
  </div>
</template>

<script>
export default {
  name: "PatientList",

  data: () => ({
    hyStageDescription: [
      { description: "Asymptomatisch", value: 0 },
      { description: "Nur einseitige Beteiligung", value: 1 },
      {
        description: "Beidseitige Beteiligung ohne Gleichgewichtsstörung",
        value: 2,
      },
      {
        description:
          "Leichte-mäßige beidseitige Beteiligung; gewisse Haltungsinstabilität, aber körperlich unabhängig; Unterstützung zum Ausgleich bei Zugtest nötig.",
        value: 3,
      },
      {
        description:
          "Starke Behinderung; kann aber noch ohne Hilfe gehen oder stehen",
        value: 4,
      },
      {
        description:
          "Ohne fremde Hilfe auf den Rollstuhl angewiesen oder bettlägerig.",
        value: 5,
      },
    ],
    disabled: false,
    postCreate: false,
    id: Math.random().toString(36).substring(2, 10).toUpperCase(),
    active: true,
    firstName: "",
    firstNameRules: [
      (value) => {
        if (/\d/.test(value)) return "First name can not contain digits.";

        return true;
      },
      (value) => {
        if (value) return true;

        return "Vorname darf nicht leer sein";
      },
    ],
    lastName: "",
    lastNameRules: [
      (value) => {
        if (/\d/.test(value)) return "Last name can not contain digits.";

        return true;
      },
      (value) => {
        if (value) return true;

        return "Nachname darf nicht leer sein";
      },
    ],
    birthDate: null,
    gender: "",
    street: "",
    zipCode: "",
    email: "",
    phone: "",
    zipCodeRules: [
      (value) => {
        // to prevent the value marked as required, also return true when the content is empty
        if (/\d/.test(value) || !value) return true;

        return "PLZ darf nur Zahlen enthalten";
      },
      (value) => {
        // to prevent the value marked as required, also return true when the content is empty
        if (value.length === 5 || !value) return true;

        return "PLZ muss aus 5 Zahlen bestehen";
      },
    ],
    city: "",
    form: null,
    familyDoctor: {
      name: "",
      street: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
    },
    neurologist: {
      name: "",
      street: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
    },
    eMailRules: [
      (value) => {
        // to prevent the value marked as required, also return true when the content is empty
        if (!value) return true;
        if (/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(value) ^ !value) return true;

        return "Bitte geben Sie eine gültige E-Mail Adresse an";
      },
    ],
    ambCare: null,
    ambCareObject: {
      name: "",
      street: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
    },
    insurer: "",
    insurerTxt: "",
    martialStatus: "",
    situLiving: "",
    situLivingTxt: "",
    motherLang: "",
    motherLangTxt: "",
    jobActive: "",
    diagnosisTime: "",
    symptomFall: {
      present: false,
      duration: "",
      last_event: "",
      frequency: "",
    },
    hyStage: "",
    notEmptyRules: [
      (value) => {
        if (value) return true;

        return "Darf nicht leer sein";
      },
    ],
    patient: {},
    qr_code: "",
  }),

  methods: {
    addPatient: function () {
      this.checkIfPatientExists();
      let patient_create_response;
      this.$refs.form.validate().then(
        (status) => {
          if (status.valid === true) {
            let patient = {
              patient_id: this.id,
              active: this.active,
              first_name: this.firstName,
              last_name: this.lastName,
              birth_date: this.birthDate,
              gender: this.gender,
              street: this.street,
              zip_code: this.zipCode,
              email: this.email,
              phone: this.phone,
              city: this.city,
              insurer: this.insurerTxt != "" ? this.insurerTxt : this.insurer,
              family_doctor: this.familyDoctor,
              neurologist: this.neurologist,
              amb_care: this.ambCare === "true" ? this.ambCareObject : false,
              martial_status: this.martialStatus,
              situ_living:
                this.situLiving === "misc"
                  ? this.situLivingTxt
                  : this.situLiving,
              mother_lang:
                this.motherLang === "misc"
                  ? this.motherLangTxt
                  : this.motherLang,
              diagnosis_time: this.diagnosisTime,
              symptom_fall: this.symptomFall,
              hy_stage: this.hyStage,
              deleted: false,
              created_at: new Date(),
            };

            this.$axios
              .post("/patient/", patient, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then(
                (response) => {
                  if ("success" in response.data) {
                    console.log("Patient created");
                  }

                  patient_create_response = response;
                },
                () => {
                  console.log("Patient not created");
                },
              )
              .then(() => {
                this.$axios
                  .post("/alert/", {
                    patient_id: this.id,
                    status: "green",
                    reason: "patient created",
                    comment: "Patient erstellt",
                    user_id: "system",
                    alert_datetime: new Date().toISOString(),
                  })
                  .then((response) => {
                    if ("success" in response.data) {
                      console.log("Initial Alert created");
                    }
                  })
                  .then(() => {
                    if ("qrcode" in patient_create_response.data) {
                      console.log("dasdasdasdasdasd");
                      this.postCreate = true;
                      this.patient = patient;
                      this.qr_code = patient_create_response.data["qrcode"];
                    } else {
                      this.$router.push("/patients");
                    }
                  });
              });
          } else {
            console.log(status);
            console.log(this.hyStage);
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },

    checkIfPatientExists: function () {
      this.$axios.get("/get_patient/" + this.id, {}).then((response) => {
        // response data neither null nor undefined --> patient with this id exists
        if (response.data != null) {
          // create new id and set exists to true to the query again with the new id
          console.log(
            "patient with id: ",
            this.id,
            " exists. Generate new id and check if this id exits.",
          );
          this.id = Math.random().toString(36).substring(2, 10).toUpperCase();
          this.checkIfPatientExists();
        } else {
          console.log("patient does not exist. Keep the ID");
        }
      });
    },
  },
};
</script>
