<template>
    <v-container>
      <v-form ref="form" :disabled="submitted" fast-fail @submit.prevent="addDocumentation">
        <v-text-field
          :readonly="disabled"
          :rules="notEmptyRule"
          v-model="dateOfProcedure"
          type="date"
          :max="new Date().toISOString().slice(0, -14)"
          label="Datum"
          @keydown="clearInput()"
        ></v-text-field>
        <v-text-field
            :readonly="disabled"
            v-model="duration"
            :rules="durationRule"
            label="Dauer (HH:MM)"
        ></v-text-field>
        <v-text-field
            :readonly="disabled"
            v-model="nurseId"
            label="Kürzel Study Nurse"
        ></v-text-field>
        <v-textarea
          :readonly="disabled"
          v-model="content"
          label="Gesprächsinhalte"
        ></v-textarea>
        <v-file-input
          v-show="!disabled"
          v-model="docFile"
          :rules="fileRules"
          accept=".pdf"
          label="PDF hochladen"
        ></v-file-input>
  
        <v-expansion-panels v-if="filePDF !== 'Not provided'">
          <v-expansion-panel>
            <v-expansion-panel-title>
              <v-col class="text-left">hochgeladenes Dokument</v-col>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <vue-pdf-embed v-show="filePDF" :source="fileBlob" />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
  
        <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
      </v-form>
    </v-container>
  </template>
  
  <script>
  import VueJWTDecode from "vue-jwt-decode";
  import VuePdfEmbed from "vue-pdf-embed";
  
  export default {
    name: "NursePatientCall",
  
    components: {
      VuePdfEmbed,
    },
  
    props: {
      document_data: Object,
      disabled_field: Boolean,
    },
  
    data: function () {
      return {
        title: "Telefonat Parkinson Nurse - Patient",
        type: "nurse_patient_call",
        billableProcedures: ["A06b"],
        filePDF: this.document_data
          ? this.document_data.content.find(
              (item) => item.property === "docFile",
            ).value
          : "Not provided",
        fileBlob: undefined,
        docFile: undefined,
        fileRules: [
          (value) => {
            return (
              !value ||
              !value.length ||
              value[0].size < 5 * 1024 * 1024 ||
              "Datei darf nicht größer als 5 MB sein!"
            );
          },
          (value) => {
            if (value) return true;
  
            return "Sie müssen ein Dokument hochladen";
          },
        ],
        notEmptyRule: [
          (value) => {
            if (value) return true;
  
            return "Dies ist ein Pflichtfeld";
          },
        ],
        duration: this.document_data
            ? this.document_data.content.find(
                (item) => item.property === "duration"
            ).value
            : "",
        durationRule: [
          (value) => {
            if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
                return true;

            return "Dauer muss im Format HH:MM angegeben werden";
          },
        ],
        dateOfProcedure: this.document_data
          ? this.document_data.treatment_date
          : "",
        disabled: this.disabled_field,
        nurseId: this.document_data
            ? this.document_data.content.find((item) => item.property === "nurseId")
                .value
            : "",
        content: this.document_data
            ? this.document_data.content.find((item) => item.property === "content")
                .value
            : "",
        submitted: false
      };
    },
  
    methods: {
      addDocumentation: function () {
        this.$refs.form.validate().then((status) => {
          if (status.valid === true) {
            this.submitted = true
            const token = this.keycloak.token;
            const decoded_token = VueJWTDecode.decode(token);
            let user_name = decoded_token.preferred_username;
  
            let patient_id = this.$route.params.id.split("&", 1)[0];
  
            let docFilePath = undefined;
  
            if (this.docFile !== undefined && this.docFile.length > 0) {
              let blob = this.docFile[0];
              let newFile = new File(
                [blob],
                "DocumentNPC_" +
                  patient_id +
                  "_" +
                  Math.floor(new Date().getTime() / 1000) +
                  ".pdf",
                { type: blob.type },
              );
              let formData = new FormData();
              formData.append("file", newFile);
  
              this.$axios
                .post("/upload/", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(
                  (response) => {
                    if ("error" in response.data) {
                      console.log(response.data);
                      return;
                    } else {
                      console.log("File Upload successfull");
                      console.log(response.data.success.path);
                      docFilePath = response.data.success.path;
                    }
                    // console.log("TreatmentDocument created")
                    // this.$emit('close-dialog');
                  },
                  (error) => {
                    console.log(error);
                  },
                )
                .then(() => {
                  let json_payload = {
                    user_id: user_name,
                    patient_id: patient_id,
                    treatment_date: this.dateOfProcedure,
                    documentation_date: new Date().toISOString(),
                    treamtent_type: this.type,
                    billable_procedures: this.billableProcedures,
                    title: this.title,
                    content: [
                      { property: "duration", value: this.duration },
                      { property: "nurseId", value: this.nurseId },
                      { property: "content", value: this.content },
                      { property: "docFile", value: docFilePath },
                    ],
                  };
  
                  console.log(json_payload);
  
                  this.$axios
                    .post("/treatment_documentation/", json_payload, {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then(
                      (response) => {
                        console.log(response);
                        if ("success" in response.data) {
                          console.log(response.data);
                          this.$emit("close-dialog");
                        } else {
                          console.log(response.data);
                        }
                      },
                      () => {
                        console.log("TreatmentDocument not created");
                      },
                    );
                });
            } else {
              let json_payload = {
                user_id: user_name,
                patient_id: patient_id,
                treatment_date: this.dateOfProcedure,
                documentation_date: new Date().toISOString(),
                treamtent_type: this.type,
                billable_procedures: this.billableProcedures,
                title: this.title,
                content: [
                  { property: "duration", value: this.duration },
                  { property: "nurseId", value: this.nurseId },
                  { property: "content", value: this.content },
                  { property: "docFile", value: "Not provided" },
                ],
              };
  
              console.log(json_payload);
  
              this.$axios
                .post("/treatment_documentation/", json_payload, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then(
                  (response) => {
                    console.log(response);
                    if ("success" in response.data) {
                      console.log(response.data);
                      this.$emit("close-dialog");
                    } else {
                      console.log(response.data);
                    }
                  },
                  () => {
                    console.log("TreatmentDocument not created");
                  },
                );
            }
          }
        });
      },
  
      fetchPDFFile: function () {
        fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
          headers: {
            Authorization: "Bearer " + this.keycloak.token,
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            var that = this; //the magic happens
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              that.fileBlob = base64data;
            };
          })
          .catch((error) => console.error("Error:", error));
      },
    },
  
    watch: {
      filePDF: {
        immediate: true,
        handler(newVal) {
          // React to route parameter change
          // For example, fetch new data based on the new parameter
          console.log(newVal);
          if (newVal != undefined) {
            this.fetchPDFFile();
          }
          console.log(this.fileBlob);
        },
      },

      clearInput: function() {
      this.dateOfProcedure = ""
    }
    },
  
    mounted() {
      console.log(this.filePDF);
    },
  };
  </script>
  