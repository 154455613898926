<script setup>
import FingerTapping from "./FingerTapping.vue";
import HandRotation from "./HandRotation.vue";
import HandMovement from "./HandMovement.vue";
import Tremor from "./Tremor.vue";
</script>

<template>
  <v-tabs v-model="tab" bg-color="grey" color="#042940" @click="tabChanged">
    <v-tab value="tapping">Finger tippen</v-tab>
    <v-tab value="handmove">Faust öffnen</v-tab>
    <v-tab value="rotation">Handdrehung</v-tab>
    <v-tab value="tremor">Tremor</v-tab>
  </v-tabs>

  <v-card-text>
    <v-window v-model="tab">
      <v-window-item value="tapping">
        <FingerTapping :patient_id="patient_id" />
      </v-window-item>

      <v-window-item value="rotation">
        <HandRotation :patient_id="patient_id" />
      </v-window-item>

      <v-window-item value="handmove">
        <HandMovement :patient_id="patient_id" />
      </v-window-item>

      <v-window-item value="tremor">
        <Tremor :patient_id="patient_id" />
      </v-window-item>
    </v-window>
  </v-card-text>
</template>

<script>
export default {
  name: "HandRotation",

  data: () => ({
    patient_id: "",
    tapping_data: [],
    tab: "",
  }),

  methods: {},

  computed: {},

  mounted() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
  },
};
</script>
