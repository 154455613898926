<script setup>
import alert_green from "../../assets/Traffic_light_green.svg";
import alert_yellow from "../../assets/Traffic_light_yellow.svg";
import alert_red from "../../assets/Traffic_light_red.svg";
// TODO: Kommentar soll Pflichtfeld sein
</script>

<template :key="componentKey">
  <!-- <v-container class="d-flex justify-center"> -->
  <v-progress-circular
    v-if="loading"
    indeterminate
    color="primary"
  ></v-progress-circular>
  <v-icon v-if="!displayAll" @click="dialog = true">mdi-pencil</v-icon>
  <img
    v-if="!loading"
    :src="latest_status"
    :height="height"
    @click="dialog = true"
  />

  <v-dialog v-if="!loading" v-model="dialog">
    <v-card>
      <v-card-title>
        <span class="text-h5">Ampelstatus</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" fast-fail @submit.prevent="addAlert">
          <v-row>
            <v-col>
              <v-select
                v-model="selectedStatus"
                :items="statusMapping"
                item-title="display"
                item-value="valueString"
                label="Neuer Ampelstatus"
                :rules="notEmptyRules"
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #title>
                      <span><img :src="item.raw.image" /></span>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-text-field
            v-model="comment"
            label="Kommentar"
            :rules="notEmptyRules"
            @keydown.enter.exact.prevent="addAlert"
          ></v-text-field>

          <v-btn v-show="!disabled" type="submit" block class="mt-2"
            >Neuen Status setzen</v-btn
          >
        </v-form>

        <v-table>
          <thead>
            <tr>
              <th class="text-left">Datum</th>
              <th class="text-left">Grund</th>
              <th class="text-left">User</th>
              <th class="text-left">Kommentar</th>
              <th class="text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(alert, index) in alerts_display" :key="index">
              <td class="text-left">
                {{
                  helpers.formatDateStringToGermanDateTime(alert.alert_datetime)
                }}
              </td>
              <td class="text-left">{{ alert.reason }}</td>
              <td class="text-left">{{ alert.user_id }}</td>
              <td class="text-left">{{ alert.comment }}</td>
              <td>
                <v-row class="d-flex align-center justify-end">
                  <img width="135" :src="alert.prev_status" />
                  <v-icon>mdi-arrow-right</v-icon>
                  <img width="135" :src="alert.status_img" />
                </v-row>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-1" variant="text" @click="dialog = false">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </v-container> -->
</template>

<script>
export default {
  props: {
    patient_id: String,
    displayAll: Boolean,
    height: Number,
  },

  data: function () {
    return {
      alerts: [],
      dialog: false,
      loading: true,
      statusMapping: [
        {
          valueString: "red",
          display: "Rot",
          image: alert_red,
        },
        {
          valueString: "yellow",
          display: "Gelb",
          image: alert_yellow,
        },
        {
          valueString: "green",
          display: "Grün",
          image: alert_green,
        },
      ],
      selectedStatus: null,
      comment: "",
      componentKey: "",
      notEmptyRules: [
        (value) => {
          if (value) return true;

          return "Darf nicht leer sein";
        },
      ],
    };
  },

  computed: {
    latest_status: function () {
      if (this.alerts.length > 0) {
        let last_update = this.alerts[0];
        switch (last_update.status) {
          case "yellow":
            return alert_yellow;
          case "red":
            return alert_red;
          default:
            return alert_green;
        }
      }
      return null;
    },
    alerts_display: function () {
      this.alerts.forEach((element, index) => {
        let date = new Date(element.date);
        element.formatted_time = date.toLocaleString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        switch (element.status) {
          case "yellow":
            element.status_img = alert_yellow;
            break;
          case "red":
            element.status_img = alert_red;
            break;
          default:
            element.status_img = alert_green;
        }

        if (index < this.alerts.length - 1) {
          switch (this.alerts[index + 1].status) {
            case "yellow":
              element.prev_status = alert_yellow;
              break;
            case "red":
              element.prev_status = alert_red;
              break;
            default:
              element.prev_status = alert_green;
          }
        } else {
          element.prev_status = null;
        }
      });

      return this.alerts;
    },
  },

  methods: {
    fetchAlerts: function () {
      this.$axios
        .get("/get_alerts/" + this.patient_id)
        .then(
          (response) => {
            this.alerts = response.data;
            if (!this.helpers.isEmpty(this.alerts)) {
              this.alerts = Object.values(this.alerts).sort(function (a, b) {
                return new Date(b.alert_datetime) - new Date(a.alert_datetime);
              });
            }
            console.log(this.alerts);
          },
          () => {}
        )
        .then(
          () => {
            this.loading = false;
          },
          () => {}
        );
    },

    closeDialog: function () {
      this.dialog = false;
      this.fetchAlerts();
    },

    addAlert: function () {
      const userInfo = this.helpers.getUserInfoFromToken(this.keycloak.token);

      this.$refs.form.validate().then(
        (status) => {
          console.log(status);
          if (status.valid === true) {
            this.$axios
              .post("/alert/", {
                patient_id: this.patient_id,
                status: this.selectedStatus,
                reason: "changed by user",
                comment: this.comment,
                user_id: userInfo.user_name,
                alert_datetime: new Date().toISOString(),
              })
              .then((response) => {
                if ("success" in response.data) {
                  console.log("Initial Alert created");
                }
              })
              .then(() => {
                this.fetchAlerts();
              })
              .then(() => {
                this.closeDialog();
              });
          } else {
            console.log(status);
            console.log(this.hyStage);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },

  created() {
    this.fetchAlerts();
  },

  mounted() {},
};
</script>
