<template>
  <v-sheet
    border="md"
    rounded="lg"
    width="100%"
    class="my-sheet pa-4 text-left mx-auto mb-2 mr-2"
  >
    <h2 class="text-h5">MDS-UPDRS > {{ active_title }}</h2>

    <v-row class="mt-n2">
      <v-col>
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn prepend-icon="mdi-filter-variant" v-bind="props" variant="plain"></v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="changePart(item.title)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col>
        <v-tabs v-model="tab" color="#042940" align-tabs="end">
          <v-tab value="net_plot">Netzgrafik</v-tab>
          <v-tab value="score_bars">Score-Balken</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-window v-model="tab">
      <v-window-item value="net_plot" eager>
        <v-row class="mt-n2">
          <v-col>
            <div ref="updrs_net_plot"><canvas></canvas></div>
          </v-col>
        </v-row>
        <v-row v-if="!helpers.isEmpty(updrs_data)" class="mt-n2">
          <v-col>
            <v-expansion-panels variant="accordion">
              <v-expansion-panel>
                <v-expansion-panel-title>
                  MDS-UPDRS {{ active_title }} vom
                  {{ helpers.formatDateStringToGermanDate(updrs_data[0].datetime) }}
                  Uhr - Score: {{ active_score }}
                </v-expansion-panel-title>

                <v-expansion-panel-text>
                  <v-row class="mt-n2">
                    <v-col v-if="loading === false">
                      <b>{{ active_title }}</b>
                      <div v-for="(part, i) in updrs_data[1][active_title_short]" :key="i">
                        <div v-if="getAnswerColor(part) === 'red'">
                          <v-row class="mb-2">
                            <v-col class="text-align:left">
                              {{ i }}:
                            </v-col>
                            <v-col class="text-align:left">
                              <p style="background-color: #FF0000;">4 schwer ausgeprägt</p>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else-if="getAnswerColor(part) === 'orange'">
                          <v-row class="mb-2">
                            <v-col class="text-align:left">
                              {{ i }}:
                            </v-col>
                            <v-col class="text-align:left">
                              <p style="background-color: #FFD700;">3 mäßig ausgeprägt </p>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else-if="getAnswerColor(part) === 'yellow'">
                          <v-row class="mb-2">
                            <v-col class="text-align:left">
                              {{ i }}:
                            </v-col>
                            <v-col class="text-align:left">
                              <p>2 leicht ausgeprägt</p>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else-if="getAnswerColor(part) === 'light-green'">
                          <v-row class="mb-2">
                            <v-col class="text-align:left">
                              {{ i }}:
                            </v-col>
                            <v-col class="text-align:left">
                              <p>1 mild ausgeprägt</p>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else-if="getAnswerColor(part) === 'green'">
                          <v-row class="mb-2">
                            <v-col class="text-align:left">
                              {{ i }}:
                            </v-col>
                            <v-col class="text-align:left">
                              <p>0 normal</p>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else>
                          <v-row class="mb-2">
                            <v-col class="text-align:left">
                              {{ i }}:
                            </v-col>
                            <v-col class="text-align:left">
                              <span> keine Antwort </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="score_bars" eager>
        <v-row class="mt-n2">
          <v-col>
            <div ref="updrs_bar_plot"><canvas style="height:3vh"></canvas></div>
          </v-col>
        </v-row>
        <v-row class="mt-n2">
          <v-col v-if="loading === false">
            <b>{{ active_title }}</b>
            <div v-for="(part, i) in updrs_data[1][active_title_short]" :key="i">
              <div v-if="getAnswerColor(part) === 'red'">
                {{ i }}: <span style="color: #FF0000;"> schwer </span>
              </div>
              <div v-else-if="getAnswerColor(part) === 'orange'">
                {{ i }}: <span style="color: #FF8080;"> moderat </span>
              </div>
              <div v-else-if="getAnswerColor(part) === 'yellow'">
                {{ i }}: <span style="color: #ffd700;"> leicht </span>
              </div>
              <div v-else-if="getAnswerColor(part) === 'light-green'">
                {{ i }}: <span style="color: #FDBC40;"> mild </span>
              </div>
              <div v-else-if="getAnswerColor(part) === 'green'">
                {{ i }}: <span style="color: #008000;"> normal </span>
              </div>
              <div v-else>
                {{ i }}: keine Antwort vorhanden
              </div>
            </div>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </v-sheet>
</template>
  
<script>
import Chart from "chart.js/auto";

export default {
  name: "UPDRSDetailedDisplay",

  data: () => ({
    updrs_data: {},
    net_graph: null,
    bar_graph: null,
    patient_id: "",
    tab: "",
    items: [ { title: "Teil I" }, { title: "Teil II" }, { title: "Teil III" }, { title: "Teil IV" } ],
    active_title: "Teil I: Erfahrungen des täglichen Lebens - nicht-motorische Aspekte",
    active_title_short: "Part 1",
    active_score: 0,
    loading: true,
  }),

  methods: {
    getUPDRSData: function () {
      this.$axios
        .get("/get_updrs/" + this.patient_id)
        .then(
          (response) => {
            console.log(response.data);
            this.updrs_data = response.data;
            console.log('updrssssss: ', this.updrs_data)
            this.active_score = this.extractScore('Part 1')
          },
          (error) => {
            console.log(error);
          }
        )
        .then(
          () => {
            console.log(this.updrs_data);
            this.netPlot('Part 1');
            this.barPlot('Part 1');
            this.loading = false
          },
          () => {}
        );
    },

    netPlot: function (part) {
      if (this.net_graph != null) {
        this.net_graph.destroy()
      }
      console.log(!this.helpers.isEmpty(this.updrs_data));
      if (!this.helpers.isEmpty(this.updrs_data)) {
        console.log("updateplot");
        var data = {
          labels: Object.keys(this.updrs_data[1][part]),
          datasets: [
            {
              label:
                "UPDRS vom " +
                this.helpers.formatDateStringToGermanDate(
                  this.updrs_data[0]["datetime"]
                ),
              data: Object.values(this.updrs_data[1][part]), // Replace with your data values
              backgroundColor: "rgba(75, 192, 192, 0.2)", // Fill color
              borderColor: "rgba(75, 192, 192, 1)", // Border color
              borderWidth: 2, // Border width
            },
          ],
        };

        // Configuration options for the Radar Chart
        var options = {
          responsive: true,
          maintainAspectRatio: false,
          scale: {
            angleLines: {
              display: true,
            },
            ticks: {
              beginAtZero: true,
              max: 10, // Adjust the maximum scale value as needed
            },
          },
        };

        console.log(this.$refs);

        var ctx_net = this.$refs["updrs_net_plot"]
          .querySelector("canvas")
          .getContext("2d");

        this.net_graph = new Chart(ctx_net, {
          type: "radar",
          data: data,
          options: options,
        });
      }
    },

    barPlot: function (part) {
      if (this.bar_graph != null) {
        this.bar_graph.destroy()
      }
      let label = 'Teil 1'
      if(part === 'Part 2') {
        label = 'Teil 2'
      } else if (part === 'Part 3') {
        label = 'Teil 3'
      } else if (part === 'Part 4') {
        label = 'Teil 4'
      }
      let score = this.extractScore(part)
      var data = {
        labels: [label],
        datasets: [{
          axis: 'y',
          data: [score],
          backgroundColor: this.getBarColor(part, score)
        }]
      }

      var ctx_bar = this.$refs["updrs_bar_plot"]
          .querySelector("canvas")
          .getContext("2d");


      this.bar_graph = new Chart(ctx_bar, {
        type: "bar",
        data: data,
        options: { indexAxis: 'y', scales: { x: { max: this.getMaximumSubscore(part) } }, plugins: { legend: { display: false } } },
      });
    },

    extractScore: function (part) {
      return this.updrs_data[0]["score"][part];
    },

    changePart: function (title) {
      if (title === "Teil I") {
        this.active_title = "Teil I: Erfahrungen des täglichen Lebens - nicht-motorische Aspekte"
        this.active_score = this.extractScore('Part 1')
        this.active_title_short = 'Part 1'
        // update the plots with the selected part
        this.netPlot('Part 1')
        this.barPlot('Part 1')
      } else if (title === "Teil II") {
        this.active_title = "Teil II: Motorische Aspekte bei Erfahrungen des täglichen Lebens"
        this.active_score = this.extractScore('Part 2')
        this.active_title_short = 'Part 2'
        // update the plots with the selected part
        this.netPlot('Part 2')
        this.barPlot('Part 2')
      } else if (title === "Teil III") {
        this.active_title = "Teil III: Motorische Untersuchung"
        this.active_score = this.extractScore('Part 3')
        this.active_title_short = 'Part 3'
        // update the plots with the selected part
        this.netPlot('Part 3')
        this.barPlot('Part 3')
      } else if (title === "Teil IV") {
        this.active_title = "Teil IV: Motorische Komplikationen"
        this.active_score = this.extractScore('Part 4')
        this.active_title_short = 'Part 4'
        // update the plots with the selected part
        this.netPlot('Part 4')
        this.barPlot('Part 4')
      } else {
        console.log('Non existing value selected')
      }
    },

    getAnswerColor: function(text) {
      if (text === 4) {
        return 'red'
      } else if (text === 3) {
        return 'orange'
      } else if (text === 2) {
        return 'yellow'
      } else if (text === 1) {
        return 'light-green'
      } else if (text === 0) {
        return 'green'
      } else {
        return ''
      }
    },

    getMaximumSubscore: function(text) {
      if (text === 'Part 1') {
        return 52
      } else if (text === 'Part 2') {
        return 52
      } else if (text === 'Part 3') {
        return 132
      } else if (text === 'Part 4') {
        return 25
      } else {
        return ''
      }
    },

    getBarColor: function(text, score) {
      if (text === 'Part 1') {
        if (score <= 10) {
          return '#008000'
        } else if (score > 10 && score <= 21) {
          return '#FDBC40'
        } else {
          return '#FF0000'
        }
      } else if (text === 'Part 2') {
        if (score <= 12) {
          return '#008000'
        } else if (score > 13 && score <= 29) {
          return '#FDBC40'
        } else {
          return '#FF0000'
        }
      } else if (text === 'Part 3') {
        if (score === 0) {
          return '#008000'
        } else if (score > 0 && score <= 32) {
          return '#FDBC40'
        } else {
          return '#FF0000'
        }
      } else if (text === 'Part 4') {
        if (score <= 0) {
          return '#008000'
        } else if (score > 0 && score <= 4) {
          return '#FDBC40'
        } else {
          return '#FF0000'
        }
      } else {
        return
      }
    },

    switchedTo: function () {
      this.getUPDRSData();
    },
  },

  mounted() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
    this.getUPDRSData();
  },

  expose: ["switchedTo"],
};
</script>
