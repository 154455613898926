<script setup>
import SymptomsWidget from "../symptoms/SymptomsWidget.vue";
import UPDRSDisplay from "./UPDRSDisplay.vue";
import TherapyDialogue from "./TherapyDialogue.vue";
import DiaryViewPV from "./DiaryViewPV.vue";
import WHO5Display3 from "./WHO5Display3.vue";
import PDQ39Display from "./PDQ39Display.vue";
</script>

<template>
  <v-row>
    <!-- Left Column -->
    <v-col cols="12">
      <v-row>
        <v-col>
          <v-container v-if="!helpers.isEmpty(patient)">
            Anschrift: <br />
            {{ patient.street + ", " + patient.zip_code + " " + patient.city }}
            <v-btn
              :to="{
                name: 'patient-update',
                params: {
                  id: patient.id,
                },
              }"
              class="btn btn-primary"
              prepend-icon="mdi-plus"
              >Patientendaten ergänzen</v-btn
            ><br />
            <p v-if="patient.phone != ''">
              Telefonnummer: <br />
              {{ patient.phone }}
            </p>
            <p v-if="patient.email != ''">
              E-Mail: <br />
              {{ patient.email }}
            </p>

            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title> Hausarzt </v-expansion-panel-title>
                <v-expansion-panel-text v-if="patient.family_doctor">
                  {{ patient.family_doctor.name }}<br />
                  {{
                    patient.family_doctor.street +
                    ", " +
                    patient.family_doctor.zip_code +
                    " " +
                    patient.family_doctor.city
                  }}<br />
                  {{
                    patient.family_doctor.phone +
                    ", " +
                    patient.family_doctor.email
                  }}
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Behandelnder Neurologe
                </v-expansion-panel-title>
                <v-expansion-panel-text v-if="patient.neurologist">
                  {{ patient.neurologist.name }}<br />
                  {{
                    patient.neurologist.street +
                    ", " +
                    patient.neurologist.zip_code +
                    " " +
                    patient.neurologist.city
                  }}<br />
                  {{
                    patient.neurologist.phone + ", " + patient.neurologist.email
                  }}
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Krankheitsspezifische Informationen
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Diagnosezeitpunkt: {{ patient.diagnosis_time }}<br />
                  <div v-if="patient.hy_stage">
                    Hoehn und Yahr Stadium: {{ patient.hy_stage }}<br />
                  </div>
                  <div v-if="patient.symptom_fall">
                    Angaben zur wiederkehrenden Stürzen: <br />
                    <p style="text-indent: 2em">
                      Dauer: {{ patient.symptom_fall.duration }} <br />
                    </p>
                    <p style="text-indent: 2em">
                      Frequenz: {{ patient.symptom_fall.frequency }} <br />
                    </p>
                    <p style="text-indent: 2em">
                      Letzter Sturz: {{ patient.symptom_fall.last_event }}
                      <br />
                    </p>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>

              <v-expansion-panel v-if="patient.amb_care">
                <v-expansion-panel-title>
                  Ambulanter Pflegedienst
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  {{ patient.amb_care.name }}<br />
                  {{
                    patient.amb_care.street +
                    ", " +
                    patient.amb_care.zip_code +
                    " " +
                    patient.amb_care.city
                  }}<br />
                  {{ patient.amb_care.phone + ", " + patient.amb_care.email }}
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-col>
      </v-row>
    </v-col>

    <!-- Right Column (Spanning both rows) -->
    <v-row class="ma-0">
      <v-col cols="6">
        <TherapyDialogue />
        <br /><br />
        <WHO5Display3 />
        <br /><br />
        <PDQ39Display />
        <br /><br />
        <DiaryViewPV />
        <br /><br />
        <UPDRSDisplay />
      </v-col>
      <v-col cols="6">
        <SymptomsWidget />
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "PatientOverview",

  data: () => ({
    patient: {},
    tab: "",
  }),

  methods: {
    fetchPatientData: function () {
      let patient_id = this.$route.params.id.split("&", 1);

      this.$axios
        .get("/get_patient/" + patient_id)
        .then(
          (response) => {
            this.patient = response.data;
            console.log(this.patient);
          },
          () => {}
        )
        .then(
          () => {},
          () => {}
        );
    },

    switchedTo: function () {
      this.fetchPatientData();
    },
  },

  computed: {
    birthDate_print: function () {
      let date = new Date(this.patient.birth_date);

      let formatted_time = date.toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      return formatted_time;
    },
    agePrint: function () {
      let today = new Date();
      let date = new Date(this.patient.birth_date);

      console.log(today - date);

      var ageDate = new Date(today - date); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
  },

  mounted() {
    this.fetchPatientData();
  },
  expose: ["switchedTo"],
};
</script>
