<template>
  <div>
    <h1>Patientendaten ergänzen</h1>
    <v-form ref="form" fast-fail @submit.prevent="addPatient">
      <v-row clas="mb-2">
        <v-col cols="8">
          <v-label class="align-left">Interventionsgruppe?</v-label>
          <v-radio-group :disabled="disabled" v-model="active">
            <v-radio label="Interventionsgruppe" v-bind:value="true"></v-radio>
            <v-radio label="Kontrollgruppe" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="!deleted" cols="4">
          <v-checkbox
            :disabled="disabled"
            v-model="deleted"
            label="Patient*in deaktivieren"
            @click="dialogCheck()"
          >
          </v-checkbox>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" persistent width="512">
        <v-card
          title="Diesen Patient deaktivieren"
          text="Sind Sie sicher, dass Sie die/den Patient*in deaktivieren möchten?"
        >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="(dialog = false), addPatient()"
            >
              Ja
            </v-btn>
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="(dialog = false), (deleted = false)"
            >
              Nein
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-text-field
        readonly="true"
        :disabled="disabled"
        v-model="firstName"
        label="Vorname"
        :rules="firstNameRules"
      ></v-text-field>
      <v-text-field
        readonly="true"
        :disabled="disabled"
        v-model="lastName"
        label="Nachname"
        :rules="lastNameRules"
      ></v-text-field>
      <v-text-field
        readonly="true"
        :disabled="disabled"
        v-model="birthDate"
        label="Geburtsdatum"
        type="date"
      ></v-text-field>

      <v-label class="align-left">Geschlecht</v-label>
      <v-radio-group
        readonly="true"
        :disabled="disabled"
        v-model="gender"
        :rules="notEmptyRules"
      >
        <v-radio label="männlich" value="male"></v-radio>
        <v-radio label="weiblich" value="female"></v-radio>
        <v-radio label="divers" value="other"></v-radio>
      </v-radio-group>

      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="phone"
            label="Telefonnummer"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="email"
            label="E-Mail Adresse"
            :rules="eMailRules"
          ></v-text-field
        ></v-col>
      </v-row>

      <v-text-field
        :disabled="disabled"
        v-model="street"
        label="Straße und Hausnummer"
      ></v-text-field>
      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="zipCode"
            label="Postleitzahl"
            :rules="zipCodeRules"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="city"
            label="Ort"
          ></v-text-field
        ></v-col>
      </v-row>

      <v-label class="align-left">Kostenträger</v-label>
      <v-select
        v-model="insurer"
        :items="[
          'AOK',
          'Barmer',
          'DAK',
          'KKH',
          'Techniker',
          'IKK Classic',
          'Knappschaft',
          'Sonstige',
        ]"
      ></v-select>

      <v-text-field
        :disabled="disabled"
        v-if="insurer == 'AOK' || insurer == 'Sonstige'"
        v-model="insurerTxt"
        :label="insurer + ' Name'"
      ></v-text-field>

      <v-label class="align-left text-h6">Behandelnder Hausarzt</v-label>
      <v-text-field
        :disabled="disabled"
        v-model="familyDoctor.name"
        label="Praxisname"
      ></v-text-field>

      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="familyDoctor.phone"
            label="Telefonnummer"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="familyDoctor.email"
            label="E-Mail Adresse"
            :rules="eMailRules"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-text-field
        :disabled="disabled"
        v-model="familyDoctor.street"
        label="Straße und Hausnummer"
      ></v-text-field>
      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="familyDoctor.zip_code"
            label="Postleitzahl"
            :rules="zipCodeRules"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="familyDoctor.city"
            label="Ort"
          ></v-text-field
        ></v-col>
      </v-row>

      <v-label class="align-left text-h6">Behandelnder Neurologe</v-label>
      <v-text-field
        :disabled="disabled"
        v-model="neurologist.name"
        label="Praxisname"
      ></v-text-field>

      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="neurologist.phone"
            label="Telefonnummer"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="neurologist.email"
            label="E-Mail Adresse"
            :rules="eMailRules"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-text-field
        :disabled="disabled"
        v-model="neurologist.street"
        label="Straße und Hausnummer"
      ></v-text-field>
      <v-row>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="neurologist.zip_code"
            label="Postleitzahl"
            :rules="zipCodeRules"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-text-field
            :disabled="disabled"
            v-model="neurologist.city"
            label="Ort"
          ></v-text-field
        ></v-col>
      </v-row>

      <v-label class="align-left text-h6"
        >Ambulanter Pflegedienst vorhanden?</v-label
      >
      <v-radio-group
        :disabled="disabled"
        v-model="ambCare"
        :rules="genderRules"
      >
        <v-radio label="Nein" value="false"></v-radio>
        <v-radio label="Ja" value="true"></v-radio>
      </v-radio-group>

      <div id="ambCoreObject" v-if="ambCare == 'true' && !disabled">
        <v-label class="align-left">Ambulanter Pflegedienst</v-label>
        <v-text-field
          :disabled="disabled"
          v-model="ambCareObject.name"
          label="Name des Pflegedienst"
        ></v-text-field>
        <v-row>
          <v-col
            ><v-text-field
              :disabled="disabled"
              v-model="ambCareObject.phone"
              label="Telefonnummer"
            ></v-text-field
          ></v-col>
          <v-col
            ><v-text-field
              :disabled="disabled"
              v-model="ambCareObject.email"
              label="E-Mail Adresse"
              :rules="eMailRules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-text-field
          :disabled="disabled"
          v-model="ambCareObject.street"
          label="Straße und Hausnummer"
        ></v-text-field>
        <v-row>
          <v-col
            ><v-text-field
              :disabled="disabled"
              v-model="ambCareObject.zip_code"
              label="Postleitzahl"
              :rules="zipCodeRules"
            ></v-text-field
          ></v-col>
          <v-col
            ><v-text-field
              :disabled="disabled"
              v-model="ambCareObject.city"
              label="Ort"
            ></v-text-field
          ></v-col>
        </v-row>
      </div>

      <v-label class="align-left text-h6">Persönliche Informationen</v-label>
      <v-row>
        <v-col
          ><v-label class="align-left">Familienstand</v-label>
          <v-radio-group :disabled="disabled" v-model="martialStatus">
            <v-radio
              label="verheiratet/Lebenspartnerschaft"
              value="married"
            ></v-radio>
            <v-radio label="unverheiratet/ledig" value="single"></v-radio>
            <v-radio label="geschieden" value="divorced"></v-radio>
            <v-radio label="verwitwet" value="widowed"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          ><v-label class="align-left">Wohnsituation</v-label>
          <v-radio-group :disabled="disabled" v-model="situLiving">
            <v-radio
              label="Privater Haushalt"
              value="private_household"
            ></v-radio>
            <v-radio
              label="Seniorenwohnung"
              value="elderly_household"
            ></v-radio>
            <v-radio label="Altersheim" value="elderly_home"></v-radio>
            <v-radio label="Pflegeheim" value="care_home"></v-radio>
            <v-radio label="Sonstige" value="misc"></v-radio>
          </v-radio-group>
          <v-text-field
            v-if="situLiving === 'misc'"
            :disabled="disabled"
            v-model="situLivingTxt"
            label="Sonstige Wohnsituation"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          ><v-label class="align-left">Muttersprache</v-label>
          <v-radio-group :disabled="disabled" v-model="motherLang">
            <v-radio label="Deutsch" value="german"></v-radio>
            <v-radio label="Sonstige" value="misc"></v-radio>
          </v-radio-group>
          <v-text-field
            v-if="motherLang === 'misc'"
            :disabled="disabled"
            v-model="motherLangTxt"
            label="Sonstige Muttersprache"
          ></v-text-field>
        </v-col>
        <v-col
          ><v-label class="align-left">Aktuell noch erwerbstätig?</v-label>
          <v-radio-group :disabled="disabled" v-model="jobActive">
            <v-radio label="Ja" value="true"></v-radio>
            <v-radio label="Nein" value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-label class="align-left text-h6"
        >Krankheitsspezifische Informationen</v-label
      >
      <v-text-field
        :disabled="disabled"
        v-model="diagnosisTime"
        type="month"
        :max="new Date().toISOString().slice(0, -17)"
        label="Zeitpunkt der Parkinson Diagnose"
      ></v-text-field>

      <v-checkbox
        :disabled="disabled"
        v-model="symptomFall.present"
        label="Wiederkehrende Stürze vorhanden?"
      ></v-checkbox>

      <v-text-field
        :disabled="disabled"
        v-if="symptomFall.present"
        v-model="symptomFall.duration"
        label="Stürze präsent seit? (Monate)"
      ></v-text-field>

      <v-text-field
        :disabled="disabled"
        v-if="symptomFall.present"
        v-model="symptomFall.last_event"
        type="month"
        :max="new Date().toISOString().slice(0, -17)"
        label="Zeitpunkt letzter Sturz"
      ></v-text-field>

      <v-text-field
        :disabled="disabled"
        v-if="symptomFall.present"
        v-model="symptomFall.frequency"
        label="Sturzhäufig (pro Woche)"
      ></v-text-field>

      <v-select
        v-model="hyStage"
        :items="hyStageDescription"
        item-title="description"
        item-value="value"
        label="Hoehn und Yahr Stadium"
      ></v-select>

      <v-btn :disabled="disabled" type="submit" block class="mt-2"
        >Submit</v-btn
      >
    </v-form>
  </div>
</template>

<script>
export default {
  name: "PatientUpdate",

  data: () => ({
    dialog: false,
    hyStageDescription: [
      { description: "Asymptomatisch", value: 0 },
      { description: "Nur einseitige Beteiligung", value: 1 },
      {
        description: "Beidseitige Beteiligung ohne Gleichgewichtsstörung",
        value: 2,
      },
      {
        description:
          "Leichte-mäßige beidseitige Beteiligung; gewisse Haltungsinstabilität, aber körperlich unabhängig; Unterstützung zum Ausgleich bei Zugtest nötig.",
        value: 3,
      },
      {
        description:
          "Starke Behinderung; kann aber noch ohne Hilfe gehen oder stehen",
        value: 4,
      },
      {
        description:
          "Ohne fremde Hilfe auf den Rollstuhl angewiesen oder bettlägerig.",
        value: 5,
      },
    ],
    disabled: false,
    postCreate: false,
    id: "",
    active: true,
    deleted: false,
    firstName: "",
    firstNameRules: [
      (value) => {
        if (/\d/.test(value)) return "First name can not contain digits.";

        return true;
      },
    ],
    lastName: "",
    lastNameRules: [
      (value) => {
        if (/\d/.test(value)) return "Last name can not contain digits.";

        return true;
      },
    ],
    birthDate: null,
    gender: "",
    street: "",
    zipCode: "",
    email: "",
    phone: "",
    zipCodeRules: [
      (value) => {
        // to prevent the value marked as required, also return true when the content is empty
        if (/\d/.test(value) || !value) return true;

        return "PLZ darf nur Zahlen enthalten";
      },
      (value) => {
        // to prevent the value marked as required, also return true when the content is empty
        if (value.length === 5 || !value) return true;

        return "PLZ muss aus 5 Zahlen bestehen";
      },
    ],
    city: "",
    form: null,
    familyDoctor: {
      name: "",
      street: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
    },
    neurologist: {
      name: "",
      street: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
    },
    eMailRules: [
      (value) => {
        // to prevent the value marked as required, also return true when the content is empty
        if (!value) return true;
        if (/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(value) ^ !value) return true;

        return "Bitte geben Sie eine gültige E-Mail Adresse an";
      },
    ],
    ambCare: null,
    ambCareObject: {
      name: "",
      street: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
    },
    insurer: "",
    insurerTxt: "",
    martialStatus: "",
    situLiving: "",
    situLivingTxt: "",
    motherLang: "",
    motherLangTxt: "",
    jobActive: "",
    diagnosisTime: "",
    symptomFall: {
      present: false,
      duration: "",
      last_event: "",
      frequency: "",
    },
    hyStage: "",
    createdAt: "",
    notEmptyRules: [
      (value) => {
        if (value) return true;

        return "Darf nicht leer sein";
      },
    ],
    patient: {},
    qr_code: "",
  }),

  methods: {
    addPatient: function () {
      this.$refs.form.validate().then(
        (status) => {
          if (status.valid === true) {
            let patient = {
              patient_id: this.id,
              active: this.active,
              first_name: this.firstName,
              last_name: this.lastName,
              birth_date: this.birthDate,
              gender: this.gender,
              street: this.street,
              zip_code: this.zipCode,
              email: this.email,
              phone: this.phone,
              city: this.city,
              insurer: this.insurerTxt != "" ? this.insurerTxt : this.insurer,
              family_doctor: this.familyDoctor,
              neurologist: this.neurologist,
              amb_care: this.ambCare === "true" ? this.ambCareObject : false,
              martial_status: this.martialStatus,
              situ_living:
                this.situLiving === "misc"
                  ? this.situLivingTxt
                  : this.situLiving,
              mother_lang:
                this.motherLang === "misc"
                  ? this.motherLangTxt
                  : this.motherLang,
              diagnosis_time: this.diagnosisTime,
              symptom_fall: this.symptomFall,
              hy_stage: this.hyStage,
              deleted: this.deleted,
              created_at: this.createdAt,
            };

            this.$axios
              .post("/patient/", patient, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then(
                (response) => {
                  console.log(response);
                  if ("info" in response.data) {
                    console.log("Patient updated");
                    if (this.deleted === true) {
                      this.$router.push({
                        name: "patient-list",
                        params: { active: true },
                      });
                    } else {
                      this.$router.push({
                        name: "patient-view",
                        params: {
                          id: patient.patient_id,
                          view: "overview_view",
                        },
                      });
                    }
                  }
                },
                () => {
                  console.log("Patient not updated");
                }
              );
          } else {
            console.log(status);
            console.log(this.hyStage);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    fetchPatientData: function () {
      this.$axios.get("/get_patient/" + this.$route.params.id).then(
        (response) => {
          let patient = response.data
          if (patient.patient_id == this.$route.params.id) {
            this.patient = patient;
            console.log(this.patient);
            // now assign the fetched patient values back into the form data to show current patient data
            this.id = patient.patient_id;
            this.active = patient.active;
            this.deleted = patient.deleted;
            this.firstName = patient.first_name;
            this.lastName = patient.last_name;
            this.birthDate = patient.birth_date;
            this.gender = patient.gender;
            this.street = patient.street;
            this.zipCode = patient.zip_code;
            this.email = patient.email;
            this.phone = patient.phone;
            this.city = patient.city;
            this.insurer = patient.insurer;
            this.familyDoctor = patient.family_doctor;
            this.neurologist = patient.neurologist;
            this.ambCare = patient.ambCare;
            this.martialStatus = patient.martial_status;
            this.situLiving = patient.situ_living;
            this.motherLang = patient.mother_lang;
            this.diagnosisTime = patient.diagnosis_time;
            this.symptomFall = patient.symptom_fall;
            this.hyStage = patient.hy_stage;
            this.createdAt = patient.created_at;
            }
          },
        () => {}
      );
    },

    dialogCheck: function () {
      // need to check the opposite, because 'deleted' gets it's new value after this operation
      if (!this.deleted) {
        this.dialog = true;
      }
    },
  },

  mounted() {
    this.fetchPatientData();
  },
};
</script>
