<template>
  <v-sheet
    border="md"
    rounded="lg"
    width="100%"
    class="my-sheet pa-4 text-left mx-auto mb-2 mr-2"
  >
    <h2 class="text-h5 mb-6">Symptome</h2>
    <v-btn-toggle
      clasS="d-inline"
      v-model="toggle_multiple"
      background-color="blue-darken-4"
      multiple
    >
      <v-btn class="centered-button" value="fall">
        <v-avatar size="10" color="yellow-accent-2"></v-avatar>
        <div class="centered-text">Stürze</div>
      </v-btn>

      <v-btn class="centered-button" value="sleep">
        <v-avatar size="10" color="light-blue-accent-1"></v-avatar>
        <div class="centered-text">Schlafstörung</div>
      </v-btn>

      <v-btn class="centered-button" value="digestion">
        <v-avatar size="10" color="light-green"></v-avatar>
        <div class="centered-text">Verdauungsstörungen</div>
      </v-btn>

      <v-btn class="centered-button" value="mobility">
        <v-avatar size="10" color="blue-darken-4"></v-avatar>
        <div class="centered-text">Mobilitätsstörungen</div>
      </v-btn>
      <v-btn class="centered-button" value="tremor">
        <v-avatar size="10" color="grey"></v-avatar>
        <div class="centered-text">Tremor</div>
      </v-btn>
      <v-btn class="centered-button" value="hospital">
        <v-avatar size="10" color="purple"></v-avatar>
        <div class="centered-text">Hospitalisierung</div>
      </v-btn>
      <v-btn class="centered-button" value="acute">
        <v-avatar size="10" color="pink"></v-avatar>
        <div class="centered-text">Akute Beschwerden</div>
      </v-btn>
    </v-btn-toggle>
    <div v-if="symptomsList.length > 0">
      <v-row
        v-for="(data, key, index) in symptomsListDisplay"
        :key="index"
        class="mt-n2"
      >
        <div>
          <v-col>
            <strong> {{ helpers.formatDateStringToGermanDate(key) }} </strong>
            <v-divider></v-divider>
            <div v-for="(resource, index) in data" :key="index">
              <v-row>
                <v-col class="centered-btn">
                  <v-avatar
                    size="15"
                    :color="
                      Object.values(mapping).find(
                        (item) => item.code == resource.code.coding[0].code
                      ).color
                    "
                  ></v-avatar>
                  <div class="centered-text">
                    <strong
                      >{{
                        convertToGermanDate(
                          new Date(resource.effectiveDateTime)
                        )
                      }}
                      - Patient*in meldet
                      {{
                        Object.values(mapping).find(
                          (item) => item.code == resource.code.coding[0].code
                        ).display
                      }}</strong
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="resource.valueString != 'None'">
                <div
                  v-if="resource.valueString != 'None'"
                  class="centered-text mt-n4"
                >
                  <div
                    v-for="(entry, key) in JSONStringToObject(
                      resource.valueString
                    )"
                    :key="key"
                  >
                    <div v-if="checkIfArray(entry)">
                      <div
                        v-for="(entry_nested, key_nested) in entry"
                        :key="key_nested"
                      >
                        <div v-if="checkIfDate(entry_nested)">
                          {{ key }}: {{ convertToGermanDate(new Date(entry)) }}
                        </div>
                        <div v-else>{{ key }}: {{ entry_nested }}</div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="checkIfDate(entry)">
                        {{ key }}: {{ convertToGermanDate(new Date(entry)) }}
                      </div>
                      <div v-else>{{ key }}: {{ entry }}</div>
                    </div>
                  </div>
                  <br />
                </div>
              </v-row>
            </div>
          </v-col>
        </div>
      </v-row>
      <br />
      <v-row class="mt-n2">
        <v-btn
          v-if="!showAll"
          @click="(showAll = true), filterList(toggle_multiple)"
          >Alle Anzeigen</v-btn
        >
        <v-btn
          v-if="showAll"
          @click="(showAll = false), filterList(toggle_multiple)"
          >Erste 10 anzeigen</v-btn
        >
      </v-row>
    </div>
    <div v-else>
      <br />
      Es wurden bisher keine Symptome gemeldet
    </div>
  </v-sheet>
</template>

<style>
.custom-avatar {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* Makes the avatar circular */
  overflow: hidden;
  /* Ensures content doesn't overflow */
}

.centered-btn {
  display: flex;
  align-items: center;
  /* Vertically center content */
  /* Optional: Add padding or margin as needed */
}

.centered-text {
  margin-left: 8px;
  align-items: center;
  /* Adjust spacing between avatar and text as needed */
  /* Optional: Add additional styles to the text */
}
</style>

<script>
import helpers from "../helpers/helpers";

export default {
  name: "SymptomsWidget",

  data: () => ({
    patient_id: "",
    symptomsList: [],
    symptomsListDisplay: [],
    toggle_multiple: [],
    mapping: {
      fall: { code: "1912002", color: "yellow-accent-2", display: "Sturz" },
      sleep: {
        code: "39898005",
        color: "light-blue-accent-1",
        display: "Schlafstörung",
      },
      digestion: {
        code: "300390002",
        color: "light-green",
        display: "Verdauungsstörung",
      },
      mobility: {
        code: "82971005",
        color: "blue-darken-4",
        display: "Mobilitätsstörungen",
      },
      tremor: { code: "26079004", color: "grey", display: "Tremor" },
      hospital: {
        code: "32485007",
        color: "purple",
        display: "Hospitalisierung",
      },
      acute: { code: "49634009", color: "pink", display: "akute Beschwerden" },
    },
    showAll: false,
  }),

  methods: {
    filterList: function (categories) {
      if ((categories == undefined) | (categories.length == 0)) {
        // no selected categories -> get the symptoms again (will be sliced if no limit is set, but it's per default limited)
        this.getSymptoms();
        return;
      }

      let codes = [];

      categories.forEach((element) => {
        codes = codes.concat(this.mapping[element].code);
      });

      let resources = [];
      codes.forEach((element) => {
        resources = resources.concat(
          this.symptomsList.filter(
            (value) => value.code.coding[0].code == element
          )
        );
      });

      resources = Object.values(resources).sort(function (a, b) {
        return new Date(b.effectiveDateTime) - new Date(a.effectiveDateTime);
      });

      // if limit is set then slice the first ten elements
      if (!this.showAll && this.symptomsList.length >= 10) {
        resources = resources.slice(0, 10);
      }

      const groupedData = resources.reduce((acc, item) => {
        // Extract the date portion without the time

        console.log(item);
        const dateWithoutTime = item.effectiveDateTime.split("T")[0];

        // Check if the date exists in the accumulator
        if (!acc[dateWithoutTime]) {
          acc[dateWithoutTime] = [];
        }

        // Push the item to the corresponding date
        acc[dateWithoutTime].push(item);

        return acc;
      }, {});
      this.symptomsListDisplay = groupedData;
    },

    getSymptoms: async function () {
      return new Promise((resolve, reject) => {
        // Simulate an asynchronous operation (e.g., fetching data)
        this.$axios
          .get("/get_symptoms/", {
            params: {
              patient_id: this.patient_id,
            },
          })
          .then(
            (response) => {
              this.symptomsList = [];
              console.log(response.data);
              if (helpers.isEmpty(response.data)) {
                return;
              }
              response.data.forEach((element) => {
                this.symptomsList.push(element.resource);
              });
              // removing all duplictates before sorting them ascending
              this.removeDuplicates();
              this.symptomsList = Object.values(this.symptomsList).sort(
                function (a, b) {
                  return (
                    new Date(b.effectiveDateTime) -
                    new Date(a.effectiveDateTime)
                  );
                }
              );
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          )
          .then(() => {
            // if limit is set then slice the first ten elements (per default limited, if showAll is set true
            // the filterList function is called beforehand which calls this function again and then the list will be sliced)

            // copy into temp to keep all the original elements stored in symptomsList
            let temp = this.symptomsList;
            if (this.symptomsList.length >= 10 && !this.showAll) {
              temp = temp.slice(0, 10);
            }
            const groupedData = temp.reduce((acc, item) => {
              // Extract the date portion without the time
              const dateWithoutTime = item.effectiveDateTime.split("T")[0];

              // Check if the date exists in the accumulator
              if (!acc[dateWithoutTime]) {
                acc[dateWithoutTime] = [];
              }

              // Push the item to the corresponding date
              acc[dateWithoutTime].push(item);

              return acc;
            }, {});
            this.symptomsListDisplay = groupedData;
            resolve(this.symptomsList);
          });
      });
    },

    JSONStringToObject: function (json_str) {
      // if first and last chars ain't curly braces (which would indicate a json string), adjust the string
      // so that it becomes a json string
      const regex = /^{.*}$/;
      if (regex.test(json_str)) {
        return JSON.parse(json_str);
      } else {
        let str = '{"Grund": ';
        return JSON.parse(str.concat('"', json_str, '"}'));
      }
    },

    checkIfDate: function (test_var) {
      return isNaN(Date.parse(test_var)) ? false : true;
    },

    checkIfArray: function (test_var) {
      return Array.isArray(test_var);
    },

    convertToGermanDate: function (date) {
      return date
        .toLocaleTimeString("de", { timeZone: "Europe/Berlin" })
        .slice(0, 5);
    },

    increment: function () {
      this.counter = this.counter + 1;
      console.log(this.counter);
    },

    removeDuplicates: function () {
      let temp = []
      this.symptomsList.forEach((el) => {
        if (!temp.find((c) => c.effectiveDateTime === el.effectiveDateTime)) {
          temp.push(el)
        }
      })
      this.symptomsList = temp
    }
  },
  watch: {
    toggle_multiple(newVal) {
      this.getSymptoms().then(() => this.filterList(newVal));
    },
  },

  computed: {},

  mounted() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
    this.getSymptoms();
  },
};
</script>
