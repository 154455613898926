<template>
  <v-sheet
    border="md"
    rounded="lg"
    width="100%"
    class="my-sheet pa-4 text-left mx-auto mb-2 mr-2"
  >
    <h2 class="text-h5 mb-6">PDQ39</h2>
    <v-row class="mt-n2">
      <v-col>
        <div v-if="hasData" ref="pdq39_plot"><canvas></canvas></div>
        <div v-else><br />Daten nicht vorhanden</div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "PDQ39Display",

  data: () => ({
    score_data: {},
    graph: null,
    patient_id: "",
    months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    labels: [],
    labelsDaily: [],
    // date with a copy of labels daily, will carry the scores after assignment
    data: [],
    datasets: [],
    temp: [],
    patient: {},
    hasData: false,
  }),

  methods: {
    getPDQ39Data: function () {
      this.$axios
        .get("/get_scores_visualisation/" + this.patient_id, { params: { param: Boolean(this.patient.active) } })
        .then(
          (response) => {
            console.log(response.data);
            this.score_data = response.data;
            if(!this.helpers.isEmpty(this.score_data)) {
              this.hasData = true;
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .then(
          () => {
            if (this.hasData) {
              this.transformData();
            }
          },
          () => {}
        );
    },

    transformData: function () {
      // get the last seven months as labels
      let currMonth = new Date().getMonth()
      for (let i = 0; i < 7; i++) {
        console.log('num', currMonth)
        this.labels.push(this.months[currMonth])
        currMonth = currMonth - 1
        // set to december if januar was reached
        if (currMonth < 0) {
          currMonth = this.months.length-1
        }
      }
      this.labels = this.labels.reverse()
      console.log('labels', this.labels)

      // make a label for each day from the last seven months
      let curr = new Date().getFullYear()
      for (let i = 0; i < this.labels.length; i++) {
        let days = 30
        // if month has 31 days, set days to 31, same with february with 29 days
        if(this.labels[i] === 'Jan' || this.labels[i] === 'Mar' || this.labels[i] === 'Mai' || this.labels[i] === 'Jul' 
          || this.labels[i] === 'Sep' || this.labels[i] === 'Aug' || this.labels[i] === 'Okt' || this.labels[i] === 'Dez') {
            days = 31 
        } else {
            if(this.labels[i] === 'Feb') {
                days = 29
            }
        }

        for (let j = 0; j < days; j++) {
            this.labelsDaily.push((j+1) + '.' + this.labels[i] + '.' + curr)
        }
      }
      console.log(this.labelsDaily)
      this.data = this.labelsDaily.map((x) => x)


      // get the keys and extract their month for each key
      let vals = Object.values(this.score_data[1])
      // keys are here the date strings
      let keys = Object.keys(this.score_data[1])
      // add empty element to arr because reduce skips the first element, so the 'real' first element can be detected
      let arr = [{month: '', date: new Date(), score: 0}]
      for (let i = 0; i < keys.length; i++) {
        let parts = keys[i].match((/(\d+)/g))
        console.log('parts', parts)
        let date = new Date(parts[2], parts[1]-1, parts[0])
        let monthNumber = date.getMonth()
        
        // get the date str in dd.mm.yyyy format
        let dateStr = (date.getDate() + '.' + this.months[monthNumber] + '.' + date.getFullYear())
        arr.push({day: dateStr, score: vals[i]})
      }
      console.log(arr)

      // group the scores from the same day together
      const groupedItems = arr.reduce((acc, item) => {
        let day = item.day
        if (!acc[day]) {
          acc[day] = []
        }
        acc[day].push(item.score)
        return acc
      })
      console.log(groupedItems)

      // check if one day of the last seven months (labelsDaily) is present in the grouped scores (groupedItems) data
      // if yes, give its scores to the data array at idx i. if not, the data array at this position becomes null
      for (let i = 0; i < this.labelsDaily.length; i++) {
        if (this.labelsDaily[i] in groupedItems) {
          this.data[i] = groupedItems[this.labelsDaily[i]]
        } else {
          this.data[i] = null
        }
      }
      console.log(this.data)

      // find out the maximum of scores per month to find out how many datasets are needed
      // generate at least one dataset
      let maxOfScoresPerMonth = 1
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i]) {
          if (maxOfScoresPerMonth < this.data[i].length) {
            maxOfScoresPerMonth = this.data[i].length
          }
        }
      }
      console.log('maxPerM', maxOfScoresPerMonth)

      // insert into each dataset one score value from each month (null if no scores for one month are left)
      for (let i = 0; i < maxOfScoresPerMonth; i++) {
        let dataset = []
        for (let j = 0; j < this.data.length; j++) {
          if (this.data[j]) {
            dataset.push(this.data[j][i])
          } else {
            dataset.push(null)
          }
        }
        this.datasets.push({ label: i, data: dataset, backgroundColor: "rgba(75, 192, 192, 0.2)", borderColor: "rgba(75, 192, 192, 1)", borderWidth: 2, spanGaps: true})
      }
      console.log(this.datasets)
      this.updatePlot();
    },

    updatePlot: function () {
      console.log(!this.helpers.isEmpty(this.score_data));
      if (!this.helpers.isEmpty(this.score_data)) {
        console.log("updateplot");
        var data =
          {
          labels: this.labels,
          datasets: this.datasets
        }

        // Configuration options for the Radar Chart
        var options = {
          responsive: true,
          maintainAspectRatio: false,
          clip: false,
          scales: {
            y: {
              max: 100,
              beginAtZero: true,
            },
            x: {
              type: 'category',
              labels: this.labelsDaily,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 7,
                callback: function(val) {
                // show only the month of each label
                return this.getLabelForValue(val).slice(2, 5)
                },
              }
            },
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  return ctx.label + ' (Score: ' + ctx.formattedValue +')'
                }
              }
            }
          }
        };

        console.log(this.$refs);

        var ctx = this.$refs["pdq39_plot"]
          .querySelector("canvas")
          .getContext("2d");

        this.graph = new Chart(ctx, {
          type: "line",
          data: data,
          options: options,
        });
      }
    },

    fetchPatientData: function () {
      // let patient_id = this.$route.params.id.split("&", 1);
      this.$axios
        .get("/get_patient/" + this.patient_id)
        .then(
          (response) => {
            this.patient = response.data;
            console.log(this.patient);
          },
          () => {}
        )
        .then(
          () => {
            this.getPDQ39Data();
          },
          () => {}
        );
    },

    switchedTo: function () {
      this.getPDQ39Data();
    },
  },

  mounted() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
    this.fetchPatientData();
  },

  expose: ["switchedTo"],
};
</script>
