<template>
  <div ref="top_content">
    <v-card v-if="!helpers.isEmpty(patient)">
      <v-card-item class="d-flex">
        <v-card-title
          ><span class="text-h5">{{
            patient.first_name + " " + patient.last_name
          }}</span></v-card-title
        >
        <v-card-subtitle
          >Geburtsdatum: {{ birthDate_print }} (Alter: {{ agePrint }})
        </v-card-subtitle>
      </v-card-item>
    </v-card>
    <v-card-text>
      <v-alert
        title="Patient erstellt"
        text="Der Patient wurde erfolgreich erstellt. Bitte drucken Sie diese Seite samt QR-Code aus"
        type="success"
        variant="tonal"
      ></v-alert>
      <img width="450" :src="qrCodeTagString" />
      <v-btn
        @click="printPage()"
        class="btn btn-primary"
        prepend-icon="mdi-printer"
        >Seite drucken</v-btn
      >
    </v-card-text>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: "PatientPostCreate",

  props: {
    patient: Object,
    qr_code: String,
  },

  methods: {
    printPage() {
      window.print();
    },
  },

  computed: {
    qrCodeTagString: function () {
      return "data:image/jpeg;base64," + this.qr_code;
    },
    birthDate_print: function () {
      let date = new Date(this.patient.birth_date);

      let formatted_time = date.toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      return formatted_time;
    },
    agePrint: function () {
      let today = new Date();
      let date = new Date(this.patient.birth_date);

      console.log(today - date);

      var ageDate = new Date(today - date); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
  },

  mounted() {
    console.log(this.$refs);
    let content_ref = this.$refs.top_content;
    content_ref.scrollIntoView({ behavior: "instant" });
  },
};
</script>
