<template>
  <v-container>
    <v-form ref="form" :disabled="submitted" fast-fail @submit.prevent="addDocumentation">
      <v-text-field
        :readonly="disabled"
        :rules="notEmptyRule"
        v-model="dateOfCall"
        :max="new Date().toISOString().slice(0, -14)"
        type="date"
        label="Tag der Fallbesprechung"
        @keydown="clearInput()"
      ></v-text-field>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRule"
        label="Dauer der Fallbesprechung"
      ></v-text-field>

      <v-textarea
        :readonly="disabled"
        v-model="discussion.topics"
        label="Besprochene Themen (stichpunktartig)"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="discussion.measures"
        label="Besprochene Maßnahmen (stichpunktartig)"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="discussion.problems"
        label="Besprochene Problematiken"
      ></v-textarea>
      <v-textarea
        :readonly="disabled"
        v-model="discussion.interventions"
        label="Besprochene Interventionen"
      ></v-textarea>

      <v-select
        :readonly="disabled"
        v-model="selected.values"
        :items="selects"
        label="Wer bespricht Maßnahmen mit Patient?"
        multiple
        @update:model-value="checkSelections()"
      ></v-select>

      <v-textarea
        v-show="if_1"
        :readonly="disabled"
        v-model="further.documentation.content.act"
        label="anderes Vorgehen (bitte erläutern)"
      ></v-textarea>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content.further"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import VueJWTDecode from "vue-jwt-decode";

export default {
  name: "CaseDiscussionDoctor",

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Fallbesprechung Telenurse und Fachärzt*in",
      type: "case_discussion_doctor",
      dateOfCall: this.document_data ? this.document_data.treatment_date : "",
      billableProcedures: this.document_data
        ? this.document_data.billable_procedures
        : ["A08", "A09"],
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: { further: "", act: "" } },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration",
          ).value
        : "",
      durationRule: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      discussion: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "discussion",
          ).value
        : {
            problems: "",
            interventions: "",
            topics: "",
            measures: "",
          },
      disabled: this.disabled_field,
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      selects: [
        "Telenurse (telefonisch)",
        "Facharzt (telefonisch)",
        "anderes Vorgehen (bitte erläutern)",
      ],
      selected: this.document_data ? this.document_data.content.find((item) => item.property === "selected").value : { values: [] },
      if_1: false,
      submitted: false
    };
  },

  methods: {
    addDocumentation: function () {
      this.$refs.form.validate().then(
        (status) => {
          if (status.valid === true) {
            this.submitted = true
            const token = this.keycloak.token;
            const decoded_token = VueJWTDecode.decode(token);
            let user_name = decoded_token.preferred_username;

            let patient_id = this.$route.params.id.split("&", 1)[0];
            console.log(user_name);
            console.log(patient_id);

            let json_payload = {
              user_id: user_name,
              patient_id: patient_id,
              treatment_date: this.dateOfCall,
              documentation_date: new Date().toISOString(),
              treamtent_type: this.type,
              billable_procedures: this.billableProcedures,
              title: this.title,
              content: [
                { property: "duration", value: this.duration },
                { property: "discussion", value: this.discussion },
                { property: "further", value: this.further },
                { property: "selected", value: this.selected },
              ],
            };

            this.$axios
              .post("/treatment_documentation/", json_payload, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then(
                (response) => {
                  console.log(response.data);
                  this.$emit("close-dialog");
                },
                () => {
                  console.log("Patient not created");
                },
              );
          }
        },
        () => {},
      );
    },

    checkSelections: function () {
      if (this.selected.values.includes("anderes Vorgehen (bitte erläutern)")) {
        this.if_1 = true;
      } else {
        this.if_1 = false;
      }
    },

    clearInput: function() {
      this.dateOfCall = ""
    }
  },

  mounted() {
    console.log(this.disabled);
  },
};
</script>
